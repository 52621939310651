<template>
  <div class="page-principal-search">

    <section class="filter-properties">
      <router-link slot="brand" class="navbar-brand" to="/">
          <img width="93" height="59" :src="apolar_logo" alt="Apolar Imóveis">
      </router-link>

      <FormSearch
        ref="searchbox" 
        :districts="districts_selected"
        @change_district="(districts) => {districts_selected = districts}"
        v-on:closesearchform="toogleSearchForm"
        v-on:appliedfilterscount="(count) => {applied_filters_count=count}"
        v-on:updateFilterBusiness="(business) => {filters_applied['business'] = business, search_business = business}"
      />
    </section>  

    <section class="result-properties">
        <HeaderSearch/>
        <div class="container shape-container">
          

            <div class="row actions-mobile">
              <div class="col-12">
                <div class="search-setup-actions">
                    

                    <div class="search-setup-button" @click="toogleSearchForm">
                      <div class="icon"></div>
                      <label for="">Filtros</label>
                      <span v-if="applied_filters_count" class="applied-filters-count">{{applied_filters_count}}</span>
                    </div>

                    <div class="search-setup-field setup-view-mode">
                        <label for="">{{ __('Visualização') }}</label>
                        <select v-if="properties.length > 0" v-model="view_mode" v-on:change="searchOrdered" class="order-search">
                            <option value="grid">{{ __('Grid') }}</option>
                            <option value="list">Lista</option>
                        </select>
                    </div>

                    <div class="search-setup-field setup-order-by">
                        <label for="">Ordernar</label>
                        <select v-if="properties.length > 0" v-model="order_search" v-on:change="searchOrdered" class="order-search">
                            <option value="">ORDENAR POR</option>
                            <option value="price_asc">{{ __('Preço (menor - maior)') }}</option>
                            <option value="price_desc">{{ __('Preço (maior - menor)') }}</option>
                        </select>
                    </div>
                </div>
              </div>
            </div>
            
            <div class="property-search-header">
              <div v-if="total > 0" class="row">
                <div class="col-12 ">
                  <div class="total-results">
                    {{number_format(total, 0, '.', '.')}} <span v-if="total == 1">Resultado</span>  <span v-if="total > 1">Resultados</span>
                  </div>
                </div>
              </div>

              <div class="row title-row">
                <div class="col-12 col-md-6 ">
                  <h1>{{seo_title}}</h1>
                </div>
                <div class="col-6  search-setup-col">
                    
                  <div class="search-setup-actions">

                      <div class="search-setup-field">
                          <label for="">{{ __('Visualização') }}</label>
                          <select v-if="properties.length > 0" v-model="view_mode" v-on:change="searchOrdered" class="order-search">
                              <option value="grid">{{ __('Grid') }}</option>
                              <option value="list">Lista</option>
                          </select>
                      </div>
    
                      <div class="search-setup-field">
                          <label for="">Ordernar</label>
                          <select v-if="properties.length > 0" v-model="order_search" v-on:change="searchOrdered" class="order-search">
                              <option value="">ORDENAR POR</option>
                              <option value="price_asc">{{ __('Preço (menor - maior)') }}</option>
                              <option value="price_desc">{{ __('Preço (maior - menor)') }}</option>
                          </select>
                      </div>

                  </div>

                </div>
              </div>

              <div v-if="district_filters.length > 0" class="row">
                <div class="col-12">
                  <div class="district-filter">
                    
                    <VueSlickCarousel v-bind="district_options_settings">
                        <template v-for="district,key in district_filters" >
                            <div @click="toggleDistrict(district)" 
                                v-bind:key="`district_${key}`" 
                                class="district-button"
                                :class="districts_selected.indexOf(district) != -1 ? 'selected' : ''">
                                <div class="check">
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                </div>
                                <div class="label">{{district}}</div>
                            </div>
                        </template>
                    </VueSlickCarousel>

                  </div>
                </div>
              </div>
            </div>
            
            <div class="row property-search-list" :class="district_filters.length > 0 ? 'has-district-options': ''">
              <div class="col">
                <div class="list-properties">
                  
                    <div class="list-properties-wrapper">
                      <template  v-if="properties.length > 0">
                          <template v-for="property,key in properties">
                              <property 
                                v-if="isProperty(property)"
                                v-bind:key="`prop_${key}`" 
                                :layout="view_mode" 
                                :preview_images="3" 
                                :property="property"
                                :class="property['last_on_row'] ? 'last-item-row':''"
                                v-on:contactProperty="(contactproperty)=>{contact_property=contactproperty}">
                              </property>

                              <div v-bind:key="`consortium_banner_${key}`"  v-if="isAds(property,'consortium')" class="property-component ads-banner" :class="property['last_on_row'] ? 'last-item-row':''">
                                <boxConsortiumVue :send_mode="'comtemplada'" :style="propertyBoxHeight" :layout="view_mode == 'grid' ? 'box': 'banner'" :additional_emails="['performance@ops.digital']" />
                              </div>

                              <searchFullBannersVue v-bind:key="`full_banner_${key}`" v-if="isAds(property,'full')" :type="property['type']" :additional_emails="['performance@ops.digital']" />

                          </template>
                      </template>
                      <div class="clear"></div>
                    </div>

                    <div class="clear"></div>
                    
                    <div v-if="properties.length == 0 && !searching" class="no-results-wrapper">
                      <div class="no-results-msg">
                        Não encontramos imóveis com as suas preferências
                        <small>
                          Modifique os filtros ou busque em outras localidades
                        </small>
                      </div>
                    </div>


                      <div v-if="!searching && similar_properties.length > 0" class="similar-line">
                        <div class="similar-icon"></div>
                        <div class="similar-count">+{{Math.round(similar_total - (similar_total * 0.3))}} imóveis similares. <div class="arrow-icon"></div></div>
                      </div>

                      <div v-if="similar_properties.length > 0"  class="list-properties-wrapper">
                        <template v-for="property,key in similar_properties">
                            <property 
                              v-if="isProperty(property)"
                              v-bind:key="`prop_${key}`" 
                              :layout="view_mode" 
                              :preview_images="3" 
                              :property="property"
                              :class="property['last_on_row'] ? 'last-item-row':''"
                              v-on:contactProperty="(contactproperty)=>{contact_property=contactproperty}">
                              </property>

                              <div v-bind:key="`consortium_banner_${key}`"  v-if="isAds(property,'consortium')" class="property-component ads-banner" :class="property['last_on_row'] ? 'last-item-row':''">
                                <boxConsortiumVue :send_mode="'comtemplada'" :style="propertyBoxHeight" :layout="view_mode == 'grid' ? 'box': 'banner'" :additional_emails="['performance@ops.digital']" />
                              </div>

                              <searchFullBannersVue v-bind:key="`full_banner_${key}`" v-if="isAds(property,'full')" :type="property['type']" :additional_emails="['performance@ops.digital']" />

                        </template>
                        <div class="clear"></div>
                      </div>

                    <button class="load-more" v-if="scroll_id != '' && total > properties.length" v-on:click="loadMore" >Carregar mais imóveis</button>
                </div>
              </div>
            </div>

        </div>
    </section>

    <contactPropertyModalVue 
      v-if="contact_property && view_mode == 'list' && !isMobile" 
      :property="contact_property"
      :preview_images="3"
      v-on:close="()=>{contact_property=null}"
    />
  </div>
</template>
<script>
import Vue from "vue"
import "@/assets/scss/custom/principal/_page_search.scss"
import jQuery from "jquery";
import PropertiesService from "../../services/PropertiesService"
import HomeSearch from "../components/apolar/principal/home-search.vue"
import FormSearch from "../components/apolar/principal/form-search.vue"
import HeaderSearch from "../components/apolar/principal/header-search.vue"
import property from '../components/apolar/principal/property.vue';
import contactPropertyModalVue from '../components/apolar/principal/contact-property-modal.vue';
import boxConsortiumVue from '../components/apolar/principal/box-consortium.vue';
import searchFullBannersVue from '../components/apolar/principal/search-full-banners.vue';

import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


export default {
  name: "PrincipalSearch",
  components: {
    property,
    HeaderSearch,
    HomeSearch,
    FormSearch,
    VueSlickCarousel,
    contactPropertyModalVue,
    boxConsortiumVue,
    searchFullBannersVue
  },
  metaInfo() {
    return {
      title: this.seo_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.seo_description
        }
      ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href: ''
      //   }
      // ]
    };
  },
  data() {
    return {
        show_map: false,
        properties: [],
        similar_properties: [],
        map_properties: [],
        view_mode: 'grid',
        order_search: 'price_desc',
        scroll_id: '',
        similar_scroll_id: '',
        main_search_complete: false,
        total:0,
        similar_total:0,
        searching: true,
        similar_searching: false,
        show_search_form: true,
        form_filters: null,
        filters_applied: null,
        open_advanced_mode: false,
        district_filters: [],
        district_options_settings: {
          "dots": false,
          "infinite": false,
          "centerMode": false,
          "centerPadding": "20px",
          "slidesToShow": 1,
          "slidesToScroll": 1,
          "variableWidth": true
        },
        districts_selected: [],
        contact_property: null,
        applied_filters_count: null,
        propertyBoxHeightControl: 0,
        largeBannerInShow: '',
        search_business: null,
        principal_search_count:0,
        similar_search_count:0
    };
  },
  computed: {
    seo_title: function() {

      if (this.filters_applied) {

        let filters = this.filters_applied

        if ( this.lang == 'es') {
          return filters['business'] == 'Locacao' ? this.__('Imóveis para alugar') : this.__('Imóveis à venda')
        }

        let tipo_desc =  typeof filters['property_type'] != "undefined" && filters['property_type'].length > 0 ? filters['property_type'][0] : 'imóveis'

        if (tipo_desc == "Sala" && filters['business_subfilter'] == "Comercial") {
          tipo_desc = "Sala Comercial"
        }

        tipo_desc = this.capitalize(tipo_desc)

        let finalidade_desc = filters['business'] == 'Locacao' ? 'para alugar' : 'à venda'

        let title = `${tipo_desc} ${finalidade_desc}`;

        if (filters['city']) {
          let city_desc = this.capitalize(filters['city'])

          title = `${tipo_desc} ${finalidade_desc} em ${city_desc}`

          if (filters['district'] && filters['district'].length > 0) {
            let distrct_desc = this.capitalize(filters['district'][0])
            title = `${tipo_desc} ${finalidade_desc} em ${distrct_desc}, ${city_desc}`

            if (filters['address']) {
              title = `${tipo_desc} ${finalidade_desc} na ${filters['address']} em ${distrct_desc}, ${city_desc}`
            }
          }
        }

        return title
      }
      return "..."
    },
    seo_description: function() {
      if (this.filters_applied) {

        let filters = this.filters_applied

        let tipo_desc =   typeof filters['property_type'] != "undefined" && filters['property_type'].length > 0 ? filters['property_type'][0] : 'imóveis'

        if (tipo_desc == "Sala" && filters['business_subfilter'] == "Comercial") {
          tipo_desc = "Sala Comercial"
        }
        
        let finalidade_desc = filters['business'] == 'Locacao' ? 'alugar' : 'comprar'

        let desc = `Esta buscando ${tipo_desc} para ${finalidade_desc}? Conte com a Apolar para encontrar a melhor opção de acordo com suas necessidades. Acesse e Confira!`;

        if (filters['city']) {
          let city_desc = this.capitalize(filters['city'])

          desc = `Se você está buscando ${tipo_desc} para ${finalidade_desc} em ${city_desc} conte com a Apolar. Temos um time de especialistas prontos para te ajudar. Acesse e Confira!`

          if (filters['district'] && filters['district'].length > 0) {
            let distrct_desc = this.capitalize(filters['district'][0])
            desc = `Buscando ${tipo_desc} para ${finalidade_desc} no ${distrct_desc} em ${city_desc}? Conte com a Apolar, temos um time de especialistas prontos para te ajudar. Acesse e Confira!`
          }
        }

        return desc
      }
      return "..."
    },

    propertyBoxHeight: function() {
      return `height:${this.propertyBoxHeightControl}px`
    },

    apolar_logo() {
        if ( this.lang == 'es') {
            return require('@/assets/img/principal-logo-header-es.webp')
        }
        return require('@/assets/img/logo-apolar-imoveis-high.webp')
      }
  },
  methods: {
      initjQuery() {

          const main_el = jQuery(this.$el)
          const advanced_search_el = main_el.find(".apolar-advanced-search")

          main_el.find(".advanced-filters").on("click", ()=>{
              advanced_search_el.css({"bottom": `-${advanced_search_el.height() + 40}px`})
              advanced_search_el.fadeIn('fast')
          })

          jQuery(window).scroll(() => { 
              if (jQuery(window).scrollTop() >= jQuery(document).height() - jQuery(window).height() - (jQuery('.principal-footer').height() + 400)) {
                  //Add something at the end of the page
                  if (!this.searching && this.scroll_id != '' && this.total > this.properties.length ) {
                    this.loadMore()
                  }
                  
                  if (this.main_search_complete) {
                    if (!this.similar_searching && this.similar_scroll_id != '' &&  this.similar_total > this.similar_properties.length ) {
                      this.loadMoreSimilar()
                  }
                  }
                  
              }
          });

          jQuery(".property-search-header").css({'top': document.querySelector('.header_principal-property-search').clientHeight})

          window.onresize = () => {
              this.setPropertyBoxHeightControl()
          }

      },

      setPropertyBoxHeightControl() {
        if (document.querySelector('.property-component')) {
          this.propertyBoxHeightControl = document.querySelector('.property-component').clientHeight
        }
      },

      toogleSearchForm() {
        if ( jQuery('.filter-properties').hasClass('mobile-form-opened') ) {
          jQuery('.filter-properties').css({'display':'none'}).removeClass('mobile-form-opened')
        } else {
          jQuery('.filter-properties').css({'display':'block'}).addClass('mobile-form-opened')
        }
        
      },

      searchOrdered() {
          this.search(this.$advanced_filters)
      },

      search(filters, append = false) {

        if (typeof filters == 'undefined') {
          return false
        }

        var search_filters = JSON.parse(JSON.stringify(filters))

        if (typeof search_filters['business'] != 'undefined') {
          this.search_business = search_filters['business']
        }

        var insertInnerAds = false
        if ( (this.properties.length == 0 || this.properties.length == 12) || Number.isInteger(this.principal_search_count/4) ) {
          insertInnerAds = this.showAdsBannerInside()
        }
        

        search_filters['order'] = this.order_search
        search_filters['size'] =  insertInnerAds ? 11 : 12
        search_filters['fields'] = ['IsFeiraoApolar','nomeEvento','tipo','transacao','finalidade','cidade','bairro','referencia','Quartos','condominio','garagem','dormitorios','areaterreno','area_total','banheiro','ValorAnterior','valor_considerado','situacao','FimPromocao','foto_principal','endereco','linksite','Selo','finalidade','popup_fotos', 'descricao', 'vlrcondominio', 'lojacelular', 'lojatelefone', 'idtipomoeda']
        
        this.searching = true
        this.main_search_complete = false
        this.filters_applied = this.prepareFilters(search_filters)
        PropertiesService.search(this.filters_applied,  this.data_index)
        .then(
          response => {
            
            var properties = response.data
         
            if ( insertInnerAds ) {
              properties.splice((properties.length+1) * Math.random() | 0, 0, {'adsBox': 'consortium'})
            }

            properties.forEach((item, key)=>{
              properties[key]['last_on_row'] = (key+1)%3 == 0 ? true : false
            })

            if (append) {
              properties = this.properties.concat(properties)
            }
  
            if ( (properties.length == 0 || properties.length == 12) || Number.isInteger(this.principal_search_count/4) ) {
              properties.push({'adsBox': 'full', 'type': this.changeFullBannerType()})
            }

            Vue.set(this, "properties", properties)
            Vue.set(this, "scroll_id", response.scroll_id)
            Vue.set(this, 'total', response.total)

            if ( typeof filters['scroll_id'] == "undefined" && typeof response.districts_searchables != 'undefined' ) {
              Vue.set(this, "district_filters", response.districts_searchables)

              if ( this.$refs['searchbox']['filters']['district'].length > 0 ) {
                  this.$refs['searchbox']['filters']['district'].forEach((district)=>{
                    let exists = this.districts_selected.indexOf(district)
                    if (exists == -1) {
                      this.districts_selected.push(district)
                    }
                  })
              }
            }
            
            this.searching = false
            this.principal_search_count++

            setTimeout(() => {
              this.setPropertyBoxHeightControl()
            }, 100);

            // if ( typeof this.filters_applied.reference != "undefined" &&  this.filters_applied.reference) {
            //   this.$refs['searchbox'].filters.reference = null
            //   Vue.set(this, '$advanced_filters', {})
            //   Vue.set(this, '$advanced_filters', this.filters)
            // }

            if (response.data.length == 0 || properties.length >= response.total) {
              this.main_search_complete = true
              this.similarSearch()
            }

          }
        )
      },

      similarSearch(append = false, scroll_id=null) {

        this.similar_searching = true

        if (typeof this.$advanced_filters == 'undefined') {
          return false
        }
        let search_filters = JSON.parse(JSON.stringify(this.$advanced_filters))

        var insertInnerAds = false
        if ( (similar_properties.length == 0 || similar_properties.length == 18) || Number.isInteger(this.similar_search_count/4) ) {
            insertInnerAds = this.showAdsBannerInside()
        }
  
        search_filters['order'] = this.order_search
        search_filters['size'] =  insertInnerAds ? 17 : 18
        search_filters['fields'] = ['IsFeiraoApolar','nomeEvento','tipo','transacao','finalidade','cidade','bairro','referencia','Quartos','condominio','garagem','dormitorios','areaterreno','area_total','banheiro','ValorAnterior','valor_considerado','situacao','FimPromocao','foto_principal','endereco','linksite','Selo','finalidade','popup_fotos']

        if ( scroll_id ) {
          search_filters['scroll_id'] = scroll_id
        }

        let prepared_search_filters = this.prepareFilters(search_filters)

        PropertiesService.similarSearch(prepared_search_filters, 'main')
        .then(
          response => {
            this.similar_searching = false

            var properties = response.data
            

            if ( insertInnerAds ) {
              properties.splice((properties.length+1) * Math.random() | 0, 0, {'adsBox': 'consortium'})
            }


            properties.forEach((item, key)=>{
              properties[key]['last_on_row'] = (key+1)%3 == 0 ? true : false
            })

            if (append) {
              properties = this.similar_properties.concat(properties)
            }

            if (append) {
              properties = this.properties.concat(properties)
            }

            if ( (properties.length == 0 || properties.length == 18) || Number.isInteger(this.similar_search_count/4) ) {
              properties.push({'adsBox': 'full', 'type': this.changeFullBannerType()})
            }

            Vue.set(this, "similar_properties", properties)
            Vue.set(this, "similar_scroll_id", response.scroll_id)
            Vue.set(this, 'similar_total', response.total)

            this.similar_search_count++
          }
        )

      },

      loadMoreSimilar() {
        this.similarSearch(true, this.similar_scroll_id)
      },

      loadMore() {
        let next_page = {"scroll_id": this.scroll_id}
        this.search(next_page, true)
      },

      prepareFilters(filters) {

        filters['bedrooms'] = filters['bedrooms'] ? filters['bedrooms'].map(bed => typeof bed['id'] != 'undefined' ? bed['id']: bed ) : []
        filters['garage'] = filters['garage'] ? filters['garage'].map(gar => typeof gar['id'] != 'undefined' ? gar['id']: gar ) : []
        filters['bathrooms'] = filters['bathrooms'] ? filters['bathrooms'].map(bat => typeof bat['id'] != 'undefined' ? bat['id']: bat ) : []

        //caso o seja subfiltro por lancamentos deve considerar a transacao lancamentos
        if ( typeof filters['business_subfilter'] != "undefined" && filters['business_subfilter'] == "Lançamentos" ) {
          filters['business'] = "Lancamento"
        }

        //considera os tipos selecionados via botão tb
        if ( typeof filters['property_type_combo'] != "undefined" &&  filters['property_type_combo'].length > 0 ) {

          filters['property_type_combo'].forEach((type_combo)=>{

            if (type_combo) {
              let types_in_combo = type_combo.split('|')
              types_in_combo.forEach((type)=>{
                  type = type.trim()
                  if ( filters['property_type'].indexOf(type) == -1 &&  type != "Condominio" ) {
                    filters['property_type'].push(type)
                  }
              })
            }
            

          })


          // this.execOnFormFilterLoad((sf)=>{
          //   setTimeout(() => {
          //       sf.$refs['vselectpropertytype'].value = filters['property_type']
          //   }, 1000);
          // })

        }

        if (filters['price_min'] || filters['price_max']) {
          filters['price'] = [this.strToFloat(filters['price_min']), this.strToFloat(filters['price_max'])]
        } else {
          delete filters['price']
        }

        if (filters['area_min'] || filters['area_max']) {
          filters['area'] = [this.strToFloat(filters['area_min']), this.strToFloat(filters['area_max'])]
        } else {
          delete filters['area']
        }

        return filters

      },

      handle_url_params(){

          console.log(this.$route.params)

          //acesso direto, considerar parametros da url
          let url_params = JSON.parse(JSON.stringify(this.$route.params))
          let possible_purposes = ['residencial', 'comercial', 'lancamentos', 'triple-a', 'mensal', 'temporario']
          let possible_types = ['apartamento', 'barracao', 'casa', 'chacara', 'cobertura', 'conjunto', 'duplex', 'fazenda', 'flat', 
          'garagem', 'gardem', 'hotel', 'kitnet', 'lancamento', 'loft', 'ponto', 'predio', 'sala', 'sobrado', 'sobreloja',
          'studio', 'sitio', 'terreno', 'triplex', 'area', 'sala-comercial']
          
          let filter_purpose = possible_purposes.filter(p => typeof this.$route.query[p] != 'undefined')
          let purpose = filter_purpose.length > 0 ? filter_purpose[0] : null

          purpose = this.$route.params['type'] == "sala-comercial" ? "comercial" : purpose
          this.$route.params['type'] = this.$route.params['type'] == "sala-comercial" ? "sala" : this.$route.params['type']

          let bedrooms = null
          Object.keys(this.$route.params).forEach(param => {
            if ( this.$route.params[param] && this.$route.params[param].indexOf('quarto') != -1 ) {
              let tmp_bedrooms = this.$route.params[param].replace('-quartos', '').replace('-quarto', '')
              bedrooms = tmp_bedrooms.split('-')
              this.$route.params[param] = null
            }
          })
         
          url_params['purpose'] = purpose
          url_params['type'] = this.$route.params['type']
          url_params['city'] = this.$route.params['city']
          url_params['district'] = this.$route.params['district']
          url_params['address'] = this.$route.params['address']
          url_params['bedrooms'] = bedrooms

          if ( possible_types.filter(item => item == url_params.type).length == 0 ) {
            //quer dizer que acesso url direta sem usar tipo, desloca parametros
            url_params['type'] = null
            url_params['city'] = this.$route.params['type']
            url_params['district'] = this.$route.params['city']
            url_params['address'] = this.$route.params['district']
            url_params['bedrooms'] = bedrooms
          }

          return url_params
      },

      loadUrlParameters() {

        //busca originada de busca anterior, ignora
        if ( Object.keys(this.$advanced_filters).length > 0 ) {
          
          return false
        }

        //aguarda os filtros de buscas serem carregados no componenet de fomulario de filtros       
        this.execOnFormFilterLoad((sf)=>{

              console.log("deve carregar da url os parametros")

              let url_params = this.handle_url_params()

              console.log(url_params)

              //seleciona o business no form, baseado no parametro da url
              sf.filters.business = url_params.transaction == "alugar" ? "Locacao" : "Vendas"

              //selecionar o subbusiness
              if ( url_params.purpose ) {
                let sub_business_possibles = sf.filters_options.sub_filters[sf.filters.business]
                let sub_business = sub_business_possibles.filter(item => this.slugify(item.label) == url_params.purpose)
                if ( sub_business.length > 0 ) {
                  sf.filters.business_subfilter = sub_business[0].label
                }
              } else {
                sf.filters.business_subfilter =  sf.filters.business == "Locacao" ? "Mensal" : "Residencial"
              }

              //seleciona cidade e bairro
              if ( url_params.city ) {
                let filter_city = sf.filters_options.cities.filter(item => this.slugify(item.city) == url_params.city)
                
                if ( filter_city.length > 0 ) {
                  let city = filter_city[0]
                  sf.filters.city = city.city
                  sf.$refs['vselectcity'].value = sf.filters.city

                  if (url_params.district) {
                    let filter_district = city.districts.filter(item => this.slugify(item) == url_params.district)
                    if ( filter_district.length > 0 ) {
                      let district = filter_district[0]
                      sf.filters.district = [district]
                      //sf.$refs['vselectdistrict'].value = sf.filters.district
                    }
                  }
                }
              }

              //seleciona o tipo do imóvel
              if ( url_params.type ) {
                let filter_type = sf.filters_options.types.filter(item => this.slugify(item) == url_params.type)
                if ( filter_type.length > 0 ) {
                  let type = filter_type[0]
                  sf.filters.property_type = [type]
                  //sf.$refs['vselectpropertytype'].value = sf.filters.property_type
                }
              }


              //seleciona quartos
              if ( url_params.bedrooms ) {
                sf.filters.bedrooms = url_params.bedrooms
              }

              //seleciona o endereco
              if ( url_params.address ) {
                //
                let filter_address = sf.filters_options.adresses.filter(
                  item => this.slugify(item.address) == url_params.address && 
                  item.city == sf.filters.city && 
                  item.district == sf.filters.district[0]
                )

                if ( filter_address.length > 0 ) {
                  sf.use_advanced_filters =  false
                  let addrs = filter_address[0]
                  let addrs_str = `${addrs.address}, ${addrs.district}, ${addrs.city}`
                  sf.filters.address = addrs.address.trim()
                  sf.filters.open_search = addrs_str
                  setTimeout(() => {
                    sf.$refs['vselectsearchby'].$children[0].value = sf.filters.open_search
                    if ( sf.filters.property_type && sf.filters.property_type.length > 0 ) {
                      //sf.$refs['vselectpropertytype'].value = sf.filters.property_type
                    } else {
                      //sf.$refs['vselectpropertytype'].value = []
                    }
                  }, 100);
                }
              }  

              this.open_advanced_mode = true

              //load filters from query string
              if ( this.$route.query && Object.keys(this.$route.query).length > 0 ) {
                  let fields_type_array = ['bedrooms', 'garage', 'district',
                                              'property_type', 'bathrooms', 'conveniences', 
                                              'recreation', 'rooms']
                  Object.keys(this.$route.query).forEach((qfield) => {
                      let value = this.$route.query[qfield]
                      if (fields_type_array.indexOf(qfield) !== -1) {
                          if (typeof value != 'object') {
                              value = [value]
                          }

                          if (qfield == 'bedrooms' || qfield == 'garage' || qfield == 'bathrooms') {
                            value = value.map(v=>parseInt(v))
                          }
                          
                      }
                      console.log("load from query string: ", qfield, value)
                      //use_filters[qfield] = value
                      Vue.set(sf.filters, qfield, value)
                  })
                }

                if (sf.filters['property_type'].length > 1) {
                  sf.show_other_types = true
                }

              //aplica a busca     
              if (url_params.purpose == 'comercial') {
                sf.pre_purpose = 'commercial'
              }
              
              sf.enable_auto_search = true
              sf.search()
              
        })

      },

      execOnFormFilterLoad(callback) {
        let check_search_box_load = setInterval(() => {
          if ( typeof this.$refs['searchbox'] != "undefined" && this.$refs['searchbox'].all_filters_loaded == true ) {
              clearInterval(check_search_box_load)
              let searchform = this.$refs['searchbox']
              if (callback) {
                setTimeout(() => {
                    callback(searchform)
                }, 100);
              }
            }
        }, 100)
      },


      toggleDistrict(district) {

        let find_district = this.districts_selected.indexOf(district)
        if ( find_district == -1 ) {
          this.districts_selected.push(district)
        } else {
          this.districts_selected.splice(find_district, 1)
        }

      },

      showAdsBanner(key, rand_list=[1,2,3]) {
        //se estiver buscando ñ aplicar a regra para mostrar o banner
        if (this.searching || this.similar_searching) {
          return false
        }
        let show_each_three = key % 6 == 0 ? true : false
        //return show_each_three
        let randon = _.sample(rand_list)
        let show = show_each_three && randon % 3 == 0
        return show
      },

      changeFullBannerType() {
        if (this.isMobile || (!this.search_business || this.search_business && this.search_business != 'Vendas')) {
          console.log("ta caindo só aqui...", this.search_business)
          return 'procura'
        }
        return Math.random() < 0.5 ? 'condominio' : 'procura'
      },

      showAdsBannerInside() {
        if ( !this.search_business || this.search_business && this.search_business != 'Vendas' ) {
          return false
        }
        return Math.random() < 0.5
      },


      isProperty(item) {
        return typeof item['adsBox'] == 'undefined'
      },

      isAds(item, type) {
        //remove os anuncio qunado estiver no modo paraguai
        if ( this.data_index == 'py' ) {
          return false
        }
        let isAds =  typeof item['adsBox'] != 'undefined' && item['adsBox'] == type
        return isAds
      }

  },


  mounted() {
      this.initjQuery()

      if ( this.checkIsSmallScreen  ) {
        this.show_search_form = false
      }
  },
  created() {
    this.loadUrlParameters()
    this.open_advanced_mode = true
    //só buscara de primeira se houver filtro aplicado anteriormenete..
    if ( Object.keys(this.$advanced_filters).length > 0 ) {
      this.search(this.$advanced_filters)
      //this.$refs['searchbox'].enable_auto_search = true

      console.log("caindo no created....")
      this.execOnFormFilterLoad((sf)=>{
        sf.enable_auto_search = true
      })
    }


    var link = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', location.protocol + '//' + location.host + location.pathname);
    document.head.appendChild(link);


  },
  watch:{
    $advanced_filters: function(filters) {
      console.log("watch advanced_filters: ", filters)
      this.search(filters)
    }
  }
};
</script>

<style lang="scss" scoped>

  

  
</style>
