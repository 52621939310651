<template>
    <div class="consortium-contact-form-component">
            <div class="consortium-form-modal">
                <div class="modal-consortium-header">
                    <h3>Solicite uma simulação</h3>
                    <div @click="closeForm" class="close-button"></div>
                </div>

                <div class="modal-consortium-content">

                    <div v-if="form_status == 'sending'" class="send-proccess">
                        <bounce-loader :color="'#FFC20F'"></bounce-loader>
                    </div>

                    <div v-if="form_status == 'sended'" class="send-proccess">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        {{__('Solicitação de simulação enviada!')}}
                    </div>
                    
                    <div v-if="form_status == ''" class="form-content-wrapper">
                        <div class="consortium-box-mode-prices">

                            <div class="input-consortium-mode">
                                <label for="">Usar como base o valor:</label>
                                <div class="options">
                                    <label :class="form.mode == 'Parcela' ? 'active':''" for="mode_parcela">Parcela</label>
                                    <label :class="form.mode == 'Crédito' ? 'active':''" for="mode_credito">Crédito</label>
                                    <input v-model="form.mode" id="mode_parcela" type="radio" name="consortium_mode" value="Parcela">
                                    <input v-model="form.mode" id="mode_credito" type="radio" name="consortium_mode" value="Crédito">
                                </div>
                            </div>

                            <div class="input-consortium-amount">
                                <label for="">Escolha o valor desejado:</label>

                                <input :class="typeof formErrors['amount'] != 'undefined' ? 'error': ''" :style="inputDynamicSize" v-money="moneyMask"  class="input-amount" type="text" v-model="form.amount_fake">
                                
                                <div class="slider-wrapper">
                                    <range-slider
                                        class="slider"
                                        :min="amount_slide.min"
                                        :max="amount_slide.max"
                                        :step="amount_slide.step"
                                        v-model="form.amount"
                                        @change="(amount)=>{changeFormAmount(amount)}"
                                        >
                                    </range-slider>
                                    <div class="range-amount min">R$ {{amount_slide.min}}</div>
                                    <div class="range-amount max">R$ {{amount_slide.max}}</div>
                                </div>
                                
                            </div>

                        </div>

                        <div class="form-inputs-wrapper">
                            <div class="description">
                                Simples e prático, após preencher os seus dados abaixo uma equipe especializada entrará em contato.
                            </div>

                            <div class="form-inputs">

                                <div class="input" :class="typeof formErrors['name'] != 'undefined' ? 'error': ''">
                                    <label for="">Nome</label>
                                    <input v-model="form.name" type="text" placeholder="Nome">
                                </div>

                                <div class="input" :class="typeof formErrors['email'] != 'undefined' ? 'error': ''">
                                    <label for="">E-mail</label>
                                    <input v-model="form.email" type="email" placeholder="E-mail">
                                </div>

                                <div class="input" :class="typeof formErrors['phone'] != 'undefined' ? 'error': ''">
                                    <label for="">Telefone</label>
                                    <input v-mask="['(##) ####-####','(##) #####-####']" v-model="form.phone" type="text" placeholder="Telefone">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="form_status == ''" class="modal-consortium-footer">
                    <div @click="sendForm" class="button">Enviar</div>
                </div>
            </div>
    </div>  
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/custom/principal/components/_consortium_contact_form_component.scss"
import * as yup from "yup";
import RangeSlider from 'vue-range-slider'
import {mask} from 'vue-the-mask'
import {VMoney} from 'v-money'
import ContactService from '../../../../services/ContactService';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'

const validateSchema = yup.object({
    name: yup.string().required("Nome completo é obrigatório."),
    email: yup.string().required("E-mail é obrigatório.").email("E-mail inválido."),
    phone: yup.string().required("Telefone é obrigatório."),
    mode: yup.string().required("Modo de simulação é obrigatório."),
    amount: yup.number().required("Valor desejado é obrigatório.").test(
    'is-gt-zero',
    'Valor desejado é obrigatório.',
    value => parseFloat(value) > 0,
  )
})

export default {
  directives: {money: VMoney, mask},
  props: {
    send_mode: {
        type: String,
        default() {
            return "default"
        }
    },
    additional_emails: {
      type: Array,
      default() {
        return []
      }
    },
  },
  components: {
    RangeSlider,
    BounceLoader
  },
  data() {
    return {
        form: {
            mode: 'Parcela',
            amount: 350,
            amount_fake: "350,00",
            name: null,
            email: null,
            phone: null
        },
        formErrors: {},
        form_status: "",
        amount_slide: {
            min: 350,
            max: 4000,
            step: 50
        },
        moneyMask: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        }
    };
  },
  computed: {

    inputDynamicSize: function() {
        let style = ''
        if (this.form.amount_fake.length > 8) {
            let inital_size = 100
            let size = inital_size + ((this.form.amount_fake.length -8) * 10)
            style = `width: ${size}px;`
        }
        return style
    },


    form_to: function() {
        if ( this.send_mode == "comtemplada" ) {
            return 'magda.severino@consorcioapolar.com.br'
        }
        return 'ruy.lopes@consorcioapolar.com.br'
    },

  },
  methods: {
    changeFormAmount(amount) {
        this.form.amount_fake = `${amount},00`
    },

    sendForm() {
        
        this.formErrors = {}

        validateSchema
            .validate(this.form, {abortEarly: false})
            .then(async ()=>{

                this.form_status = "sending"

                let subject = this.send_mode == "comtemplada" ? "Interesse - Carta contemplada" : "Formulário Simulação Consórcio"
                
                let form_to = this.form_to

                if ( this.additional_emails.length > 0 ) {
                    form_to = [this.form_to].concat(this.additional_emails)
                }

                let data_contact = {
                    "subject": subject,
                    "to": form_to,
                    "fields": [
                        {
                            "label": "Simulando pelo valor",
                            "value": ( this.form.mode == 'Parcela' ? `da ` : 'do ' ) + this.form.mode,
                        },
                        {
                            "label": "Valor simulado",
                            "value": this.form.amount_fake,
                        },
                        {
                            "label": "Nome Completo",
                            "value": this.form.name,
                        },
                        {
                            "label": "Telefone",
                            "value": this.form.phone,
                        },
                        {
                            "label": "E-mail",
                            "value": this.form.email,
                        },
                    ],
                    "_recaptcha_token": await this.createRecaptchaToken('CONTACT')
                }

                if ( this.send_mode == "default" ) {
                    data_contact["fields"] = [{
                            "label": "URL do Imóvel",
                            "value": window.location.href,
                    }].concat(data_contact["fields"])
                }

                ContactService.sendGenericContact(data_contact)
                .then((reponse)=>{
                    this.form_status = "sended"
                })
                .catch((error)=>{
                    this.send_fail = true
                })
                .finally(()=>{
                    setTimeout(()=>{

                        if (this.form_status == "sended") {

                            Object.keys(this.form).forEach((field)=>{
                                this.form[field] = ''
                            })
                            this.form.mode = 'Parcela'
                            this.form.amount = 350
                            this.form.amount_fake = '350,00'

                            this.form_status = ""

                            this.closeForm()

                        }
                        
                        

                    },4000)
                })
                
                
            })
            .catch(err => {
                console.log(err)
                if ( typeof err.inner != "undefined" ) {
                    err.inner.forEach(error => {
                        Vue.set(this.formErrors, error.path, error.message)
                    });
                }
            })
    },

    closeForm() {
        this.$emit('closeForm', true)
    }
  },
  mounted() {
    this.loadRecaptchaTag()
  },

  watch:{

    "form.mode": function(mode) {

        if ( mode == "Parcela" ) {
            this.amount_slide.min = 350
            this.amount_slide.max = 4000
            this.amount_slide.step = 50

            this.form.amount = 350
        } else {
            this.amount_slide.min = 50000
            this.amount_slide.max = 900000
            this.amount_slide.step = 10000

            this.form.amount = 50000
        }

         this.form.amount_fake = `${this.form.amount},00`
        
    },

    "form.amount_fake": function(amount_fake) {
        amount_fake = amount_fake.replace(/[^0-9.,]/g,'').replace(/\./g,'').replace(/\,/g,'.')
        this.form.amount = parseFloat(amount_fake)
    }
  }
}
</script>
<style>
</style>
