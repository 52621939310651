<template>
  <section class="property-header-default">
    <div v-show="current_tab == 'photo'" id="property-slide">
      <PropertySeal :type="'situacao'" :property="immobile" />
      <div v-if="photos.length > 0" class="gallery-with-thumb">
        <div class="main-slide">
          <VueSlickCarousel
            ref="gallery_main_slide"
            v-bind="main_slide_settings"
            @beforeChange="syncSliders"
          >
            <img
              v-for="photo in photos"
              :src="photo.url"
              :alt="photo.comodo"
              @click="showPropetySlidePopupAction"
            />
          </VueSlickCarousel>

          <ul class="property-header-menu">
            <!-- <li class="header-menu-map" :class="current_tab == 'map' ? 'active': ''" v-on:click="changeTab('map')">
            <div class="immobile-icons map"></div>
            Mapa
            </li> -->
            <li
              v-show="video != ''"
              class="header-menu-video"
              :class="current_tab == 'video' ? 'active' : ''"
              v-on:click="changeTab('video')"
            >
              <div class="immobile-icons video"></div>
              Vídeos
            </li>
            <li
              class="header-menu-photo"
              :class="current_tab == 'photo' ? 'active' : ''"
              v-on:click="changeTab('photo')"
            >
              <div class="immobile-icons gallery"></div>
              {{ current_photo_number }}/{{ photos.length }}
            </li>
            <li v-show="tour != ''"  class="header-menu-tour" :class="current_tab == 'tour' ? 'active': ''" v-on:click="changeTab('tour')">
              <div class="immobile-icons tour"></div>
              Tour 360º
            </li>
          </ul>
        </div>
        <div class="thumb-slide">
          <VueSlickCarousel
            ref="gallery_thumb_slide"
            v-bind="thumb_slide_settings"
            @beforeChange="syncSliders"
          >
            <img v-for="photo in photos" :src="photo.url" :alt="photo.comodo" />
          </VueSlickCarousel>
        </div>
      </div>
    </div>

    <div v-show="current_tab == 'map'" id="property-map">
      <!-- <iframe :src="this.map" frameborder="0"></iframe> -->
      <Openstreet
        :refresh="current_tab == 'map'"
        :properties="[this.immobile]"
      ></Openstreet>
    </div>

    <div v-show="current_tab == 'video' && video != ''" id="property-video">
      <iframe :src="this.video" frameborder="0"></iframe>
    </div>

    <div v-show="current_tab == 'tour' && tour != ''" id="property-tour">
      <div v-on:click="current_tab = 'photo'" class="close-popup">
        Fechar <i class="fa fa-times-circle-o" aria-hidden="true"></i>
      </div>
      <iframe :src="this.tour" frameborder="0"></iframe>
    </div>

    <div v-show="showPropetySlidePopup" class="propety-slide-popup">
      <div v-on:click="showPropetySlidePopup = false" class="close-popup">
        Fechar <i class="fa fa-times-circle-o" aria-hidden="true"></i>
      </div>

      <VueSlickCarousel
        ref="full_carousel_photos"
        v-bind="main_slide_settings"
        @beforeChange="syncSliders"
        class="carousel-popup-main-photos"
      >
        <img v-for="photo in photos" :src="photo.url" :alt="photo.comodo" />
      </VueSlickCarousel>

      <div class="thumbs">
        <div class="photo-name">
          {{ current_photo_label }}
        </div>

        <div class="photo-count">
          {{ popup_photo_count }}
        </div>

        <VueSlickCarousel
          ref="full_carousel_photos_thumb"
          @beforeChange="syncSliders"
          :centerMode="true"
          :infinite="true"
          :slidesToShow="10"
        >
          <img v-for="photo in photos" :src="photo.url" :alt="photo.comodo" />
        </VueSlickCarousel>
      </div>
    </div>

    <div v-if="reserved" class="stamp-reserved">EM NEGOCIAÇÃO</div>
  </section>
</template>
<script>
import Vue from "vue";
import jQuery from "jquery";
import { Carousel, Slide } from "vue-carousel";
import Openstreet from "./openstreet.vue";
import PropertySeal from "../apolar/principal/property-seal.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    Carousel,
    Slide,
    Openstreet,
    VueSlickCarousel,
    PropertySeal,
  },
  props: {
    immobile: {
      default: {},
    },
  },
  computed: {
    current_photo_label: function () {
      return this.photos[this.current_photo_popup]["comodo"];
    },
    popup_photo_count: function () {
      return `${this.current_photo_popup + 1}/${this.photos.length}`;
    },
    reserved: function () {
      if (
        typeof this.immobile.situacao != "undefined" &&
        this.immobile.situacao.toLowerCase().indexOf("reservad") != -1
      ) {
        setTimeout(() => {
          jQuery(".stamp-reserved").css({
            right: jQuery("h1.property-title").offset().left,
          });
        }, 1000);
        return true;
      } else {
        return false;
      }
    },
    main_slide_settings: function () {
      return {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        focusOnSelect: true,
      };
    },
    thumb_slide_settings: function () {
      return {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        focusOnSelect: true,
        infinite: true,
      };
    },
  },
  data() {
    return {
      current_tab: "photo",
      photos: [],
      current_photo_number: 1,
      map: "",
      video: "",
      tour: "",
      showPropetySlidePopup: false,
      force_thumb_nav: null,
      current_photo_popup: 0,
    };
  },
  methods: {
    showPropetySlidePopupAction() {
      this.showPropetySlidePopup = true;

      setTimeout(() => {
        // let heightSliderPopup = jQuery(".propety-slide-popup .carousel-popup-main-photos").height()

        // jQuery(".propety-slide-popup .carousel-popup-main-photos").css({
        //   "top": `30%`,
        //   "margin-top": `-${(heightSliderPopup/2)}px`
        // })
        console.log(this.$refs);

        this.$refs["carousel_photos_main"].onDrag(() => {});
      }, 100);
    },

    changeThumbSlide(page) {
      console.log("oiii thumb", page);
      this.force_thumb_nav = page;
      this.current_photo_popup = page;

      console.log("thumv ref: ", this.$refs["carousel_photos_thumb"]);
      this.$refs["carousel_photos_thumb"].goTo(page);
    },

    changeMainSlide(page) {
      console.log("oiii thumb", page);
    },

    changeTab(tab) {
      this.current_tab = tab;
    },
    getUrlParameter(url, name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      var results = regex.exec(url);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    shareThis: function () {
      if (navigator.share) {
        navigator.share({
          title: `${this.immobile.condominio} - Apolar Imóveis`,
          url: window.location.href,
        });
      }
    },

    syncSliders: function (currentPosition, nextPosition) {

      this.force_thumb_nav = nextPosition;
      this.current_photo_popup = nextPosition;

      this.$refs["gallery_main_slide"].goTo(nextPosition);
      this.$refs["gallery_thumb_slide"].goTo(nextPosition);
      this.$refs["full_carousel_photos"].goTo(nextPosition);
      this.$refs["full_carousel_photos_thumb"].goTo(nextPosition);
      this.current_photo_number = nextPosition + 1;
    },
  },
  mounted() {
    console.log("tste : ", jQuery("h1.property-title").offset().left);

    jQuery(".property-header-menu").css({
      left: jQuery("h1.property-title").offset().left,
    });

    jQuery(".share-property").css({
      left:
        jQuery(".property-details-sidebar").offset().left +
        jQuery(".property-details-sidebar").width() -
        40,
    });

    var initClick = 0;
    var endClick = 0;
    jQuery("body")
      .delegate(".property-slide-item", "mousedown", () => {
        initClick = new Date().getTime() / 1000;
      })
      .delegate(".property-slide-item", "mouseup", () => {
        console.log("oi.....click..");
        endClick = new Date().getTime() / 1000;
        //console.log((endClick - initClick))
        if (endClick - initClick < 0.15) {
          this.showPropetySlidePopupAction();
        }
      });
  },
  watch: {
    immobile: function (immobile) {
      console.log("header immobile: ", immobile);
      console.log("immobile photo: ", immobile.popup_fotos.Foto);

      var photos = [];
      immobile.popup_fotos.Foto.forEach((item) => {
        let comodo =
          typeof item.COMODO != "undefined" ? item.COMODO[0] : item.Comodo[0];
        let photo = {
          comodo: comodo,
          url: item.URLArquivo[0],
        };
        photos.push(photo);
      });
      Vue.set(this, "photos", photos);

      const lat = immobile.EixoX;
      const lng = immobile.EixoY;
      this.map = `https://maps.google.com/maps?t=m&hl=pt-BR&gl=US&mapclient=embed&ie=UTF8&ll=${lat},${lng}&spn=${lat},${lng}&z=14&q=${lat},${lng}&output=embed`;

      if (typeof immobile.video != "undefined" && immobile.video.trim() != "") {
        var video = "";
        if (immobile.video.indexOf("yout") != "-1") {
          video =
            "https://www.youtube.com/embed/" +
            this.getUrlParameter(immobile.video, "v");
        }
        if (immobile.video.indexOf("youtu.be") != "-1") {
          var video_parts = immobile.video.split("/");
          var videoId = video_parts[video_parts.length - 1];
          video = "https://www.youtube.com/embed/" + videoId;
        }
        Vue.set(this, "video", video);
      }

      if (
        typeof immobile.urlFotos360 != "undefined" &&
        immobile.urlFotos360 != null &&
        immobile.urlFotos360 != ""
      ) {
        var tour = immobile.urlFotos360.replace("http:", "https:");
        Vue.set(this, "tour", tour);
      }
    },
  },
};
</script>
<style>
</style>