<template>
  <div class="page-principal-indique">
    <section class="first-section">
        <div class="container">
          <div class="container-div">
            <div class="container-div-title">
              <h1>{{ __("QUER GANHAR UM") }} <br>
                <b>{{ __("DINHEIRO EXTRA?") }}</b><br>
                <b>{{ __("INDIQUE UM IMOVEL") }}</b><br>
                {{ __("NA APOLAR") }}
              </h1>
            </div>
            <div class="homem-cel"></div>
            <div class="belts">
              <div class="position-belts">
                <div class="yellow-belt"></div>
                <div class="blue-yellow-belt"></div>
              </div>
            </div>
            <div class="gift-message"></div>
            <div>
              <div class="indique-small-circle">
              </div>
              <div class="indique-big-circle">
              </div>
            </div>
            <div class="indique-form">
              <IndiqueForm />
            </div>

          </div>
        </div>
    </section>
    <section data-ref="como-funciona">
        <div class="container">
          <HowItWorks />
        </div>
    </section>
    <section>
        <div class="container">
          <div class="indique-passo-main">
            <div class="indique-passo">
              <div class="indique-passo-title">
                <h2><b>PASSO A PASSO</b></h2>
              </div>
              <div class="container row">
                <div class="indique-steps row">
                  <div class="d-flex justify-content-center">
                    <div class="linha-vertical"></div>
                  </div>
                  <ul>
                    <li>
                      <div class="row">
                        <div class="d-flex justify-content-center"><img src="@/assets/img/indique/house.webp"
                            alt="Listar imóvel"></div>
                        <div>
                          <h5>Indique um imóvel pelo site ou compartilhando o link</h5>
                          <span>
                            Preenchendo os dados do proprietário e do imóvel ou compartilhando seu link para o próprio proprietário preencher. Automaticamente este imóvel fica sendo indicação sua.
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>

                      <div class="row">
                        <div class="d-flex justify-content-center"><img
                            src="@/assets/img/indique/woman-at-cel.webp" alt="Contato profissional">
                        </div>
                        <div>
                          <h5>Um profissional entra em contato com o proprietário</h5>
                          <span>
                            Confirma o interesse de anunciar, visita o imóvel, tira as fotos e publica no site da imobiliária parceira.
                          </span>
                        </div>
                      </div>

                    </li>
                    <li>
                      <div class="row">
                        <div class="d-flex justify-content-center"><img src="@/assets/img/indique/piggy-bank.webp"
                            alt="Recompensa financeira">
                        </div>
                        <div>
                          <h5>Pronto, você ganhou!</h5>
                          <span>
                            Assim que o imóvel for publicado, você receberá um e-mail avisando de seu crédito. O valor será pago através de depósito bancário que será creditado até o dia 18 do mês seguinte da publicação.
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="image-container">
                <div class="woman-cel"><img src="@/assets/img/indique/smilling-woman.webp" alt=""></div>
              </div>
            </div>
            <a href="https://indica.apolar.net/?sign_user=yes" target="_blank" class="btn-passo">
              <span>Quero ganhar R$150</span>
              <img :src="require(`@/assets/img/indique/small-arrow-right.webp`)" alt="">
            </a>
            
          </div>
          
        </div>
    </section>
    <section data-ref="perguntas-frequentes">
        <div class="container">
          <QuestionsSection />
        </div>
    </section>
    <section>
        <div class="container">
          <IndiqueAction />
        </div>
    </section>
  </div>
</template>
<script>
import Vue from "vue";
import "@/assets/scss/custom/principal/_indique.scss"
import ContactForm from '../components/apolar/principal/contact-form.vue';
import HowItWorks from '../components/apolar/indique/box-how-itworks.vue';
import IndiqueForm from '../components/apolar/indique/indique-form.vue';
import QuestionsSection from '../components/apolar/indique/indique-questions.vue';
import IndiqueAction from '../components/apolar/indique/indique-action.vue'

export default {
  name: "indique",
  components: {
    ContactForm,
    HowItWorks,
    IndiqueForm,
    QuestionsSection,
    IndiqueAction
  },
  metaInfo() {
    return {
      title: "Apolar Indique | Imobiliária em Curitiba - Imóveis à Venda e Locação",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Quer ganhar um dinheiro extra? Indique um imóvel na Apolar"
        }
      ]
    };
  },
  data() {
    return {

    };
  },
  computed: {
  },
  methods: {

    
  },
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName("gift-message")[0].classList.add("apear")
    }, 3000);
  }
};
</script>