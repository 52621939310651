<template>
    <div class="procura-section-component">
        <div class="row">

          <div class="col-md-6 how-do">
            <img width="1" height="1" alt="Garota procurando imóvel pela internet" src="@/assets/img/apolar-procura-section-image.webp" />

            <carousel v-if="!isMobile" class="procura-boxes" :perPageCustom="[[300, 1],[400, 1],[800, 2],[1000, 2]]">
                <slide v-for="box,bkey  in boxes" v-bind:key="`box_question_${bkey}`">
                    <div class="procura_box">
                        <div class="title">“{{box.title}}”</div>
                        <div class="description">{{box.description}}</div>
                        <a target="_blank" :href="box.link" class="link">Ver mais</a>
                    </div>
                </slide>
            </carousel>

          </div>

          <div class="col-md-6 call-to-action">
            <h2>Ainda não achou um lugar para chamar de lar?</h2>
            <div class="description">
              Conheça o Apolar Procura. A ferramenta que conecta pessoas que estão vendendo com quem está procurando.
            </div>
            <a target="_blank" href="https://procura.apolar.com.br/" class="know-apolar-procura">CONHEÇA O APOLAR PROCURA</a>

            <carousel v-if="isMobile" class="procura-boxes" :perPageCustom="[[300, 1],[400, 1],[800, 2],[1000, 2]]">
                <slide v-for="box,bkey  in boxes" v-bind:key="`box_question_${bkey}`">
                    <div class="procura_box">
                        <div class="title">“{{box.title}}”</div>
                        <div class="description">{{box.description}}</div>
                        <a target="_blank" :href="box.link" class="link">Ver mais</a>
                    </div>
                </slide>
            </carousel>
          </div>

        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/custom/principal/components/_procura_section.scss"
import { Carousel, Slide } from "vue-carousel";


export default {
  components: {
    Carousel,
    Slide
  },
  computed: {
  },
  data() {
    return {
       boxes: [
        {
          "title": "Como eu achei meu imóvel",
          "description": "Veja o relato da Isabel e descubra como ela encontrou a casa do jeitinho que ela queria.",
          "link": "https://procura.apolar.com.br/"
        },
        {
          "title": "Vendi sem angariar",
          "description": "Daniel nunca imaginou vender através de uma imobiliária sem precisar angariar o imóvel.",
          "link": "https://procura.apolar.com.br/"
        }
       ]
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>
<style>
</style>
