<template>
    <header class="header-global header-default" :class="getHeaderClass">
        <base-nav class="navbar-main" type="" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                <img src="@/assets/img/logo-apolar.webp" alt="Apolar Imóveis">
            </router-link>
            
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                <li class="nav-item input-search">
                    <button>
                        <span>Econtrar Imóveis</span>
                        <i class="fa fa-search search-immobiles-icon" aria-hidden="true"></i>
                        <div class="clear"></div>
                    </button>

                    <VSelectInfinite
                        :options="options"
                        :placeholder="'Cidade, Nome do empreendimento...'"
                        v-model="selected_option"
                        @option:selected="onSelectFilter"
                    />

                </li>
                <li class="nav-item input-search-mobile">
                    <i class="fa fa-search search-immobiles-icon" aria-hidden="true"></i>
                </li>
                <li class="nav-item">
                    <span class="menu-item menu-apolar">
                        <span class="icon icon-advertise"></span>
                        <span class="text">ANUNCIE</span>
                    </span>
                </li>
                <li class="nav-item">
                    <span class="menu-item menu-customer-area">
                        <span class="icon icon-customer-area"></span>
                        <span class="text">ÁREA CLIENTE</span>
                    </span>
                </li>
                <li class="nav-item">
                    <span class="menu-item menu-apolar">
                        <span class="icon icon-menu"></span>
                        <span class="text">MENU</span>
                    </span>
                </li>
            </ul>
        </base-nav>
        <MenuOptions/>
        <CustomerAreaOptions/>
    </header>
</template>
<script>
import Vue from 'vue'
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import MenuOptions from "../views/components/apolar/menu-options.vue";
import CustomerAreaOptions from "../views/components/apolar/customer-area-options.vue";
import jQuery from "jquery";
import VSelectInfinite from "../views/components/apolar/VSelectInfinite.vue"

import PropertiesService from "../services/PropertiesService"

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
    MenuOptions,
    CustomerAreaOptions,
    VSelectInfinite
  },
  computed: {
      getHeaderClass() {
          return `header_${this.$route.name}`
      }
  },
  data() {
    return {
        options: [],
        selected_option: null,
        filters: {
            city:'',
            condominium: '',
            reference: ''
        },
        cities: [],
        condominiums: [],
        references: []
    };
  },
  methods: {

      initjQuery() {

        jQuery(".menu-apolar").on("click", (elm) => {
            if ( !this.isMobile ) {
                const pos = jQuery(elm.target).offset()
                jQuery(".apolar-menu-options").css({"top": pos.top + 20, "left": pos.left - 70})
            }
            jQuery(".apolar-menu-options").fadeIn()
        })

        jQuery(".menu-customer-area").on("click", (elm) => {
            if ( !this.isMobile ) {
                const pos = jQuery(elm.target).offset()
                jQuery(".customer-area-options").css({"top": pos.top + 20, "left": pos.left - 70})
            }
            jQuery(".customer-area-options").fadeIn()
        })


        jQuery(".apolar-menu-options").on("mouseleave", ()=>{
            jQuery(".apolar-menu-options").fadeOut()
        })

        jQuery(".customer-area-options").on("mouseleave", ()=>{
            jQuery(".customer-area-options").fadeOut()
        })

        jQuery(".open-apolar-advanced-filters").on("click", function(){
            jQuery(".apolar-advanced-search").slideDown()
        })

        jQuery(".input-search-mobile").click(function(){

            if ( jQuery(".input-search").hasClass("openned") ) {
                jQuery(".input-search").removeClass("openned")
                jQuery(".input-search").fadeOut()
            } else {
                jQuery(".input-search").addClass("openned")
                jQuery(".input-search").fadeIn()
            }
            
        })

        jQuery(".search-immobiles input[type='text']").keyup((e) => {
            if(e.keyCode == 13) {
                this.$router.push({
                    name: "lancamentos-property-search"
                });
            }
        });

      },


      prepareAutoCompleteFilters() {

        PropertiesService.getFilters('main')
        .then(
            response => {
                let cities = response.data.cities.map(item => { return item.city })
                let types = response.data.types
                let references = response.data.referencias
                let adresses = response.data.enderecos

                let options = cities.concat(references, adresses)

                Vue.set(this, "options", options)
            }
        )

      },

      onSelectFilter(param) {
          console.log("selecioando param: ", param)
          console.log("selecioando vmodel: ", this.selected_option)

          if ( this.isMobile ) {
              jQuery(".input-search").removeClass("openned")
            jQuery(".input-search").fadeOut()
          }

           this.filters.city = this.cities.indexOf(param) != -1 ? param.trim() : ''
           this.filters.condominium = this.condominiums.indexOf(param) != -1 ? param.trim() : ''
           this.filters.reference = this.references.indexOf(param) != -1 ? param : ''

            Vue.set(this, '$advanced_filters', {})
            Vue.set(this, '$advanced_filters', this.filters)

            this.$router.push({
                name: "lancamentos-property-search",
                query:{uid:  Math.random().toString(16).slice(2)},
                params: {filters: this.filters}
            });

      }


  },
  mounted() {
      this.initjQuery()
      this.prepareAutoCompleteFilters()
  }
};
</script>
<style>
</style>
