<template>
    <footer class="principal-footer">
        <div class="container">

            <hr class="topline">

            <div class="row">
                <div class="col-md-7 col-sm-12">
                    <img width="200" height="36" class="footer-logo" alt="Apolar Imóveis" title="Apolar Imóveis" :src="apolar_logo">
                    <div v-if="lang == 'ptBR'" class="footer-resume">
                        <p>Os melhores imóveis em Curitiba e Região Metropolitana  estão na Apolar Imóveis, <a target="_blank" href="/institucional">imobiliária em Curitiba</a> com mais de 50 anos de atuação no mercado. Na Apolar você tem toda a segurança para 
                        <a  target="_blank" href="/alugar">alugar imóveis</a>, vender ou <a  target="_blank" href="/comprar">comprar imóveis</a>.  Com mais de 10.000 imóveis disponíveis e uma rede integrada com mais de 60 lojas, com 
                        <a  target="_blank" href="/alugar/curitiba">imóveis em Curitiba</a> e Região Metropolitana.</p>
                        <p>Imóveis residenciais e comerciais ou para comprar e <a  target="_blank" href="/temporada">alugar na temporada</a>?  Pensou Imóveis, Pense Apolar.</p>
                    </div>

                    <div v-if="lang == 'es'" class="footer-resume">
                        <p>Apolar Inmobiliaria en Paraguay ofrece una amplia gama de propiedades en venta y alquiler en las principales ciudades del país, como Asunción, Ciudad del Este, San Lorenzo, Luque, Encarnación y Lambaré. Encuentre su casa ideal, apartamento, terreno o local comercial con la confianza y experiencia de Apolar. Ofrecemos asesoramiento integral en la compra, venta y alquiler de inmuebles, garantizando transparencia y seguridad en todas nuestras transacciones.</p>
                        <!-- <p>¿Propiedades residenciales y comerciales o para comprar y alquilar en la temporada? Pensó en inmbuebles, pensó Apolar.</p> -->
                    </div>

                    <div id="ra-verified-seal"></div>
                </div>

                <div class="col-md-5 col-sm-12 footer-menus">
                    <div class="row">

                        <div class="col-md-6 col-6">
                            <div class="footer-title">{{__('ENCONTRAR IMÓVEIS')}}</div>
                            <ul class="footer-menus-columns">
                                <li v-for="menu,id in menu_vertical" :key="id">
                                    <a  target="_blank" v-bind:key="id" :href="menu.link">{{__(menu.label)}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 col-6">
                            <div class="footer-title">{{__('INSTITUCIONAL')}}</div>
                            <ul class="footer-menus-columns">
                                <li v-for="menu,id in menu_institucional" :key="id">
                                    <a v-if="showMenuItem(menu.label)"  target="_blank" v-bind:key="id" :href="menu.link">{{__(menu.label)}}</a>
                                </li>
                            </ul>
                        </div>

                        <div v-if="lang != 'es'" class="col-md-6 col-6 deskt-mt-menu">
                            <div class="footer-title">{{__('PROPRIETÁRIO')}}</div>
                            <ul class="footer-menus-columns">
                                <li v-for="menu,id in menu_owner" :key="id">
                                    <a  target="_blank" v-bind:key="id" :href="menu.link">{{__(menu.label)}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-6 col-6 deskt-mt-menu">
                            <div class="footer-title">{{__('CONTATO')}}</div>
                            <ul class="footer-menus-columns">
                                <li v-for="menu,id in menus.contato" :key="id">
                                    <a  v-if="showMenuItem(menu.label)" target="_blank" v-bind:key="id" :href="menu.link">{{__(menu.label)}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        &copy; {{year}}
                        {{__('Copyright – Apolar Imóveis | Todos os direitos reservados')}}
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                        <li v-if="lang == 'ptBR'" class="nav-item">
                            <a alt="Facebook -Apolar Imóveis" target="_blank" href="https://pt-br.facebook.com/ApolarImoveis">
                                <i class="fa fa-facebook"></i>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a target="_blank" href="https://twitter.com/apolar">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li> -->
                        <li v-if="lang == 'ptBR'" class="nav-item">
                            <a alt="Youtube - Apolar Imóveis" target="_blank" href="https://www.youtube.com/user/apolarweb">
                                <i class="fa fa-youtube-play"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a alt="Linkedin - Apolar Imóveis" target="_blank" :href="linkedinUrl">
                                <i class="fa fa-linkedin-square"></i>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a alt="Intagram - Apolar Imóveis" target="_blank" :href="instagramUrl">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import "@/assets/scss/custom/principal/_footer.scss"

export default {
  name: 'principal-footer',
  computed: {
    menu_vertical() {
        if ( this.lang == 'es' ) {
            let exclude = ['Temporada', 'Triple A', 'Apolar Short Stay']
            return this.menus.vertical.filter(m => exclude.indexOf(m.label) == -1)
        }
        return this.menus.vertical
    },

    menu_institucional() {
        if ( this.lang == 'es' ) {
            let exclude = ['Consórcio']
            return this.menus.institucional.filter(m => exclude.indexOf(m.label) == -1)
        }
        return this.menus.institucional
    },

    menu_owner() {
        if ( this.lang == 'es' ) {
            let exclude = ['Apolar Procura', 'Investimentos']
            return this.menus.owner.filter(m => exclude.indexOf(m.label) == -1)
        }
        return this.menus.owner
    },

    apolar_logo() {
        if ( this.lang == 'es') {
            return require("@/assets/img/logo-footer-es.webp")
        }
        return require("@/assets/img/logo-footer.webp")
    },

    linkedinUrl() {
        if ( this.lang == 'es') {
            return 'https://www.linkedin.com/company/apolar-inmuebles'
        }
        return 'https://pt.linkedin.com/company/apolar-imoveis'
    },

    instagramUrl() {
        if ( this.lang == 'es') {
            return 'https://www.instagram.com/apolar.inmuebles'
        }
        return 'https://www.instagram.com/apolarimoveis'
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      menus: {
          vertical: [
              {"label":"Comprar","link":"/venda"},
              {"label":"Alugar","link":"/alugar"},
              {"label":"Vender","link":"/anuncie"},
              {"label":"Temporada","link":"/temporada"},
              {"label":"Triple A","link":"/triplea"},
              {"label":"Apolar Short Stay","link":"https://shortstay.apolar.com.br/"},
              {"label":"Mapa do Site","link":"https://whos.amung.us/stats/maps/2h5ao7ph70/"},
          ],
          institucional: [
              {"label":"Sobre a Apolar","link":"/institucional/"},
              {"label":"Nossas Lojas","link":"/franquias/"},
              {"label":"FAQ","link":"/faq/"},
              {"label":"Blog","link":"/blog"},
              {"label":"Consórcio","link":"https://consorcioapolar.com.br"},
              {"label":"Área Restrita","link":"https://old.apolar.com.br/franquias-analise-de-dados"},
              {"label":"Política de cookies","link":"/politica-de-cookies"},
          ],
          contato: [
              {"label":"Fale Conosco","link":"/fale-conosco/"},
              {"label":"Trabalhe Conosco","link":"/trabalhe-conosco/"},
              {"label":"Seja um Franqueado","link":"https://franqueado.apolar.com.br"},
              {"label":"Franquias","link":"/franquias/"},
          ],
          owner: [
            {"label":"Anuncie seu Imóvel","link":"/anuncie"},
            {"label":"Apolar Procura","link":"https://procura.apolar.com.br/"},
            {"label":"Investimentos","link":"/invista"},
          ]
      }
    }
  },
  methods: {
    showMenuItem(label) {
        if ( this.lang == 'es' ) {
            if ( label == 'Área Restrita' ) return false
            if ( label == 'Trabalhe Conosco' ) return false
            if ( label == 'Seja um Franqueado' ) return false
            if ( label == 'Nossas Lojas' ) return false
            if ( label == 'Blog' ) return false
            if ( label == 'Franquias' ) return false
        }
        return true
    }
  }
};
</script>
<style>
</style>
