<template>
    <div 
        class="validation-message-component"
        :class="`field_${field}`">
        {{errors[field]}}
    </div>
</template>
<script>
import jQuery from "jquery";

export default {
  components: {},
  props: {
    errors: {
      type: Object,
      default() {
        return {}
      }
    },
    field: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
  watch:{
  }
};
</script>
<style>
</style>