<template>
  <div class="page-principal-institutional">
        <div class="page-header-highlight" :class="`header-${sub_page}`">
            <img :src="header_highlight_img" width="1" height="1" alt="Saiba mais sobre nossa historia">
        </div>
        <div class="container">
            <div class="col-12">
                <div class="row">
                    <div class="col-md-4 col-12">
                        <div class="box-menu">
                            <ul>
                                <li :class="sub_page == 'main' ? 'active':''"><span @click="go()">{{__('Sobre a Apolar')}}</span></li>
                                <li :class="sub_page == 'proposito' ? 'active':''"><span @click="go('proposito')">{{__('Propósito')}}</span></li>
                                <li :class="sub_page == 'parceiros' ? 'active':''"><span @click="go('parceiros')">{{__('Parceiros')}}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-8 col-12">

                        <div v-if="sub_page == 'main'" class="content main-content">

                            <h1>{{__('Pensou imóveis, Pense Apolar')}}</h1>
                            <div class="text-block">
                                <p>{{__('Nosso slogan representa o que fazemos, trabalhamos com imóveis em diferentes frentes, trazemos as soluções que você procura no mercado imobiliário.')}}</p>
                                <p>{{__('A confiança que conquistamos de nossos clientes é refletida em nossa carteira com mais de 5.000 propriedades locadas e 10.000 imóveis disponíveis para venda e locação.')}}</p>
                                <p>{{__('Investimos em tecnologia, usabilidade e design para garantir que haja segurança, conforto e agilidade em cada etapa da sua experiência com a Apolar.')}}</p>
                                <p>{{__('Desde o momento do contato com uma de nossas lojas, passando pelos anúncios, até a etapa final de compra, venda ou locação.')}}</p>
                                <p>{{__('Dessa forma nós já ajudamos milhares de proprietários e inquilinos a ter êxito na experiência no atendimento Apolar.')}}</p>
                            </div>

                            <h2 class="mt-4">{{__('Diferenciais')}}</h2>
                            <div class="text-block">
                                <ul>
                                    <li>{{__('Mais de 50 anos de atuação no mercado imobiliário;')}}</li>
                                    <li>{{__('A única com selo AABF, excelência em franchising;')}}</li>
                                    <li>{{__('10.000 imóveis disponíveis;')}}</li>
                                    <li>{{__('9.000 acessos no portal Apolar diariamente;')}}</li>
                                    <li>{{__('Acessos ao redor do mundo;')}}</li>
                                    <li>{{__('Rede integrada com mais de 60 lojas.')}}</li>
                                </ul>
                            </div>

                            <h2 class="mt-4">{{__('História')}}</h2>
                            <div class="text-block">
                                <p>{{__('Fundada em 1969, por Joseph Galiano, a Apolar Imóveis se consolidou como a principal e mais lembrada imobiliária do Paraná. Atuou como cônsul da França em Curitiba e sempre foi respeitado pela sua ética, dedicação e empreendedorismo.')}}</p>
                                <p>{{__('O nome Apolar surgiu devido ao projeto Apollo 11, quando o homem comprovou ser possível pisar na lua.')}}</p>
                                <p>{{__('Em 1995, Jean-Michel e Daniel Galiano tornaram a Apolar, pioneira no processo de Franchising no Mercado Imobiliário, a única com selo ABF de qualidade. Hoje somos uma rede integrada com mais de 60 lojas distribuídas no Paraná, Santa Catarina, São Paulo, EUA e Europa.')}}</p>
                            </div>
                            
                            <iframe v-if="lang == 'ptBR'" height="500" src="https://www.youtube.com/embed/19W-e_wpbqk?si=x-XPRXbS-rynoUyo&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                        </div>

                        <div v-if="sub_page == 'proposito'" class="content proposito-content">
                            <h1>{{__('Propósito')}}</h1>
                            <h2 class="mt-4">{{__('Missão')}}</h2>
                            <div class="text-block">
                                <p>{{__('Proporcionar a melhor experiência a seus clientes na prestação de serviços imobiliários.')}}</p>
                            </div>

                            <h2 class="mt-4">{{__('Visão')}}</h2>
                            <div class="text-block">
                                <p>{{__('Ser uma rede sustentável de negócios imobiliários que gere felicidade às pessoas.')}}</p>
                            </div>

                            <h2 class="mt-4">{{__('Valores')}}</h2>
                            <div class="text-block">
                                <ul>
                                    <li>{{__('Respeito')}}</li>
                                    <li>{{__('Agilidade')}}</li>
                                    <li>{{__('Inovação')}}</li>
                                    <li>{{__('Flexibilidade')}}</li>
                                    <li>{{__('Sinergia')}}</li>
                                    <li>{{__('Desenvolvimento pessoal')}}</li>
                                    <li>{{__('Sentimento de pertencimento')}}</li>
                                    <li>{{__('Gastar menos do que se ganha')}}</li>
                                </ul>
                            </div>
                        </div>

                        <div v-if="sub_page == 'parceiros'" class="content parceiros-content">
                            <h1>{{__('Parceiros')}}</h1>

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 48%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/abf.webp')" alt="Associação Brasileira de Franchising">
                                </div>
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 52%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/comeducacao.webp')" alt="COM Educação">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 44%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/abmi.webp')" alt="Associação Brasileira de Mercado Imobiliário">
                                </div>
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 50%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/crecipr.webp')" alt="Creci PR">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 66%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/acp.webp')" alt="Associação Comercial do Paraná">
                                </div>
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 60%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/credpago.webp')" alt="Credpago">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 36%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/ademipr.webp')" alt="ADEMI-PR">
                                </div>
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 29%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/fae.webp')" alt="Fae">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 48%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/iamcham.webp')" alt="AMCHAM Brasil 100">
                                </div>
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 40%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/portoseguro.webp')" alt="Porto Seguro">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 56%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/bradesco.webp')" alt="Bradesc">
                                </div>
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 50%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/mapfre.webp')" alt="Mapfre">
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 33%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/bni.webp')" alt="BNI">
                                </div>
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 68%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/sindimoveis.webp')" alt="Sindicato dos Corretores de Imóveis no Estado do Paraná">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 32%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/cmapr.webp')" alt="Câmara de Mediaçnao e Arbitragem do Paraná">
                                </div>
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 56%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/santander.webp')" alt="Santander">
                                </div>
                                
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 65%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/camaravalores.webp')" alt="Câmara de Valores Imobiliários">
                                    <img width="1" height="1" style="width: 77%; height: auto; margin-top: 30px" :src="require('@/assets/img/institutional/partners-seals/ccifranca.webp')" alt="Câmara de Comércio França-Brasil">
                                </div>
                                <div class="col-md-6 col-12">
                                    <img width="1" height="1" style="width: 31%; height: auto;" :src="require('@/assets/img/institutional/partners-seals/secovipr.webp')" alt="Sindicato de Habitação e Condomínios">
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
import Vue from "vue";
import "@/assets/scss/custom/principal/_institutional.scss"

export default {
  name: "institutional",
  components: {
  },
  metaInfo() {
    return {
      title: this.__("Saiba mais sobre nossa historia | Imobiliária em Curitiba - Imóveis à Venda e Locação"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content:"Acesse e conheça um pouco mais sobre a pioneira em Franchising Imobiliário do Brasil. Acesse e confira!"
        }
      ]
    };
  },
  data() {
    return {
        sub_page: 'main'
    };
  },
  computed: {
    header_highlight_img: function() {
        switch (this.sub_page) {
            case 'proposito':
                return require(`@/assets/img/institutional/institutional-proposito.webp`)
                break;
            case 'parceiros':
                return require(`@/assets/img/institutional/institutional-parceiros.webp`)
                break;
            default:
                return require(`@/assets/img/institutional/institutional-main.webp`)
                break;
        }
    }
  },
  methods: {
    go(path=null) {
        this.sub_page = !path ? 'main': path
        this.$router.push({
            name: 'principal-institutional',
            params: {
                path: path
            }
        })
    }
  },
  mounted() {
    this.sub_page = typeof this.$route.params.path == 'undefined' ? 'main' : this.$route.params.path
  }
};
</script>