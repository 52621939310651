import MainService from './MainService'
class ApolarEventService extends MainService {

    constructor() {
        super()
        this.api_name = "ApolarNew"
        this.api_path = "apolar-event"
    }

    registerEvent = async(data) => {
        return this.doRequest("post",`register`, data)
    }

}

export default new ApolarEventService();