<template>
    <header class="header-global header-lancamentos" :class="getHeaderClass">
        <base-nav class="navbar-main" type="" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/lancamentos">
                <img src="@/assets/img/lancamentos/logo-apolar-lancamentos-black.webp" width="1" height="1" alt="Apolar Lançamentos">
            </router-link>
            
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                <li v-if="!isMobile" class="nav-item">
                    <a class="header-nav-button portal-button" href="/">Portal Apolar</a>
                </li>

                <li v-if="!isMobile" class="nav-item">
                    <a class="header-nav-button login-corretor-button" href="#">
                        <img src="@/assets/img/lancamentos/cadeado.webp" width="1" height="1" alt="Login Corretor">
                        Login corretor
                    </a>
                </li>

                <li class="nav-item">
                    <span class="menu-item menu-apolar">
                        <img src="@/assets/img/lancamentos/header-menu.webp" width="1" height="1" alt="Menu Lançamentos">
                        <span class="text">MENU</span>
                    </span>
                </li>
            </ul>
        </base-nav>
        <MenuOptions></MenuOptions>
        <!-- <AdvancedSearch></AdvancedSearch> -->
    </header>
</template>
<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import MenuOptions from "../views/components/apolar/menu-options.vue";
import AdvancedSearch from "../views/components/apolar/advanced-search.vue";
import jQuery from "jquery";

import PropertiesService from "../services/PropertiesService"

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
    MenuOptions,
    // AdvancedSearch,
  },
  computed: {
      getHeaderClass() {
          return `header_${this.$route.name}`
      }
  },
  data() {
    return {
        options: [],
        selected_option: null,
        filters: {
            city:'',
            condominium: '',
            reference: ''
        },
        cities: [],
        condominiums: [],
        references: []
    };
  },
  methods: {

      initjQuery() {

        jQuery(".menu-apolar").on("click", (elm) => {
            if ( !this.isMobile ) {
                const pos = jQuery(elm.target).offset()
                jQuery(".apolar-menu-options").css({"top": pos.top + 55, "left": pos.left - 70})
            }
            jQuery(".apolar-menu-options").fadeIn()
        })

        jQuery(".apolar-menu-options").on("mouseleave", ()=>{
            jQuery(".apolar-menu-options").fadeOut()
        })

        jQuery(".open-apolar-advanced-filters").on("click", function(){
            jQuery(".apolar-advanced-search").slideDown()
        })

        jQuery(".input-search-mobile").click(function(){

            if ( jQuery(".input-search").hasClass("openned") ) {
                jQuery(".input-search").removeClass("openned")
                jQuery(".input-search").fadeOut()
            } else {
                jQuery(".input-search").addClass("openned")
                jQuery(".input-search").fadeIn()
            }
            
        })

        jQuery(".search-immobiles input[type='text']").keyup((e) => {
            if(e.keyCode == 13) {
                this.$router.push({
                    name: "lancamentos-property-search"
                });
            }
        });

      },


      prepareAutoCompleteFilters() {

        PropertiesService.getFilters()
        .then(
            response => {
                let cities = response.data.cities.map(item => { return item.city })
                let condominiums = response.data.condominios
                let references = response.data.referencias

                let options = cities.concat(condominiums, references)

                Vue.set(this, "options", options)
                Vue.set(this, "cities", cities)
                Vue.set(this, "condominiums", condominiums)
                Vue.set(this, "references", references)

                
            }
        )

      },

      onSelectFilter(param) {
          console.log("selecioando param: ", param)
          console.log("selecioando vmodel: ", this.selected_option)

          if ( this.isMobile ) {
              jQuery(".input-search").removeClass("openned")
            jQuery(".input-search").fadeOut()
          }

           this.filters.city = this.cities.indexOf(param) != -1 ? param.trim() : ''
           this.filters.condominium = this.condominiums.indexOf(param) != -1 ? param.trim() : ''
           this.filters.reference = this.references.indexOf(param) != -1 ? param : ''

            Vue.set(this, '$advanced_filters', {})
            Vue.set(this, '$advanced_filters', this.filters)

            this.$router.push({
                name: "lancamentos-property-search",
                query:{uid:  Math.random().toString(16).slice(2)},
                params: {filters: this.filters}
            });

      }


  },
  mounted() {
      this.initjQuery()
      this.prepareAutoCompleteFilters()
  }
};
</script>
<style>
</style>
