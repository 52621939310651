<template>
  <div class="page-condominios-home">

    <section class="top-banner wrapper" :style="`background-image: url(${highlighted_image})`">
        <div class="container">
            <div class="box-info">
                <h1 class="condominium-name">{{cond_name}}</h1>
                <h2 class="condominium-address">{{cond_address}}</h2>

                <button v-on:click="goToForm">{{__('ENTRAR EM CONTATO')}}</button>
            </div>
        </div>
    </section>

    <section v-if="gallery.length > 0" class="gallery wrapper">

      <VueSlickCarousel v-bind="slideSettings">
          <template v-for="item,key in gallery" >
              <img v-bind:key="`gallery_item_${key}`" :src="item.img">
          </template>
      </VueSlickCarousel>

    </section>

    <section class="highlights wrapper">
      <ul class="highlights-options">
        <li v-for="item,key in highlights" v-bind:key="`high_${key}`" >
          <img :src="item.img" alt="">
          <div class="label">{{item.label}}</div>
          <div class="value">{{item.value}}</div>
        </li>
      </ul>
    </section>

     <section class="description wrapper">
        <h2 class="condominium-name">{{__('Condomínio')}} {{cond_name}}</h2>
        <div class="main-description">{{cond_description}}</div>
        <div class="sub-description">{{cond_subdescription}}</div>
    </section>

    <section class="differences wrapper">
        <div class="differences-wrapper">
            <template v-for="item, key in differences_list">
                <div v-bind:key="`item_${key}`" class="item">
                    {{item}}
                </div>
            </template>
        </div>
    </section>


    <section v-if="1!=1" class="amounts wrapper">
      <div class="info-area">
        <div class="info-title">{{__('Qual a média para morar neste condominio?')}}</div>
        <div class="info-text">{{__('Utilizamos informações com base a todos os imóveis que estão em nossa administração ou ja estiveram no Condomínio ' + cond_name)}}</div>
      </div>

      <div class="amount-area">
        <div class="amount-item">
          <div class="icon moeny"></div>
          <div class="label">Valor médio do condominio</div>
          <div class="value">R$ 680</div>
        </div>

        <div class="amount-item">
          <div class="icon cash"></div>
          <div class="label">Valor médio para alugar</div>
          <div class="value">R$ 1.500</div>
        </div>

        <div class="amount-item">
          <div class="icon cash"></div>
          <div class="label">Valor médio para comprar</div>
          <div class="value">R$ 309.000</div>
        </div>
      </div>
    </section>


    <section v-if="available_units.length > 0" class="available-units wrapper">
      <h2>{{__('UNIDADES DISPONÍVEIS ')}}</h2>
      <div class="available-units-list">
        <template  v-for="unit,key in available_units">
          <property :property="unit" v-bind:key="`unit_${key}`" />
        </template>
      </div>
    </section>

    <section class="schedule-visit wrapper">
        {{__('Agende sua visita!')}}
    </section>

    <secton class="contact-area wrapper">

      <div class="contact-info">
        <h3>{{__('Tire suas dúvidas em nossa central de atendimento.')}}</h3>
        <div class="info">
          {{__('Este é um canal aberto entre você e a nossa equipe de atendimento. Fique à vontade em deixar suas dúvidas, ou agendar uma visita no ')}} {{cond_name}}
        </div>
        <div v-if="phone" class="contact">
          {{__('Informações: ')}} {{useMask(phone, ['(##) #### ####', '(##) # #### ####'])}}
        </div>
      </div>

      <div class="contact-form">
        <form action="">
          <h4>{{__('Gostou de alguma unidade?')}}</h4>
          <div class="sub">
            {{__('Preencha seus dados abaixo e entraremos em contato')}}
          </div>

          <div v-if="sending" class="send-message">
              <bounce-loader :color="'#FFC20F'"></bounce-loader>
          </div>

          <div v-show="inTransaction && sendSuccess" class="contact-send success">
              <i class="fa fa-check-circle" aria-hidden="true"></i>
              {{__("Contato enviado!")}}
          </div>

          <div v-show="inTransaction && sendFail" class="contact-send fail">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              {{__("Houve uma falha ao tentar enviar seu contato.")}}
          </div>

          <div v-if="!inTransaction" class="form-inputs">
            <div class="input">
              <label for="">{{__('Nome')}}</label>
              <input type="text" :class="!this.validations.fullname ? 'error' : ''" v-model="contact.fullname" :placeholder="__('Nome')">
            </div>

            <div class="input">
              <label for="">{{__('E-mail')}}</label>
              <input type="text" :class="!this.validations.email ? 'error' : ''" v-model="contact.email" :placeholder="__('E-mail')">
            </div>

            <div class="input-with-send">
              <div class="input">
                <label for="">{{__('Telefone')}}</label>
                <input type="text" :class="!this.validations.fone ? 'error' : ''" v-mask="'(##) #####-####'" v-model="contact.fone" :placeholder="__('Telefone')">
              </div>
              <div class="button" @click="send">{{__('Enviar')}}</div>
            </div>
          </div>
        
          <div class="buttons-area">
            <div v-if="whatsapp" @click="openWhatsapp" >{{__('Fale pelo whatsapp')}}</div>
            <div v-if="phone" @click="openPhone" >
              <span v-if="!show_phone" >{{__('Fale pelo telefone')}}</span>
              <span v-else >{{useMask(phone, ['(##) #### ####', '(##) # #### ####'])}}</span>
            </div>
          </div>

        </form>
      </div>

    </secton>

    <section v-if="others_condominiums.length > 0" class="other-condominiuns wrapper">
        <h3>{{__('Confira outros condomínios nos bairros próximos')}}</h3>
        <div class="subtitle">{{__('Sugestão de condomínios na região esperando por você!')}}</div>
        <div class="list-condominiuns">
          <VueSlickCarousel v-bind="othersCondSlideSettings">
              <template v-for="item,key in others_condominiums" >
                 <div>
                     <div  v-bind:key="`other_${key}`" class="condominium-item">
                      <div class="district">{{item['district']}}</div>
                      <div class="info">
                        <div class="name-and-link">
                          <div class="name">
                            <span>Condominio</span>
                            <span class="cond-name">{{item['name']}}</span>
                          </div>
                          <button  @click="OpenTab(item['link'])">
                            <i class="fa fa-chevron-right" aria-hidden="true"></i>
                          </button>
                        </div>
                        <div class="address">{{item['address']}}</div>
                      </div>
                  </div>
                 </div>
              </template>
          </VueSlickCarousel>
        </div>
    </section>

    <section class="apolar-procura-section wrapper">
      <div class="container">
        <ProcuraSection/>
      </div>
    </section>

  </div>
</template>
<script>
import Vue from "vue";
import {mask} from 'vue-the-mask'
import ProcuraSection from "../components/apolar/principal/procura-section.vue";
import PropertiesService from '../../services/PropertiesService';
import ContactService from '../../services/ContactService';
import property from '../components/apolar/condominios/property.vue';

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'


export default {
  name: "condominios-home",
  directives: {mask},
  components: {
    ProcuraSection,
    VueSlickCarousel,
    property,
    BounceLoader
  },
  metaInfo() {
    return {
      title: this.property_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.property_description
        }
      ]
    };
  },
  data() {
    return {
      slug: null,
      condominium: null,
      available_units: [],
      condominiums_arround: [],
      show_phone: false,
      sending: false,
      sendSuccess: false,
      inTransaction: false,
      sendFail: false,
      contact: {
          url: "",
          title: "",
          fullname: '',
          email: '',
          fone: '',
          to:''
      },
      validations: {
          fullname: true,
          email: true,
          fone: true
      },
    };
  },
  computed: {
    property_title() {
        if ( this.condominium ) {
          return `${this.condominium.nome}`
        }
        return ''
    },

    property_description() {
      if ( this.condominium ) {
        return `Conheça o ${this.condominium.nome}, ${this.condominium.descricao.substr(0, this.condominium.descricao.indexOf('.'))}.`
      }
      return ''
    },

    cond_name() {
        if ( this.condominium ) {
          return this.condominium.nome
        }
        return ''
    },

    cond_address() {
        if ( this.condominium ) {
          return this.condominium.endereco
        }
        return ''
    },

    cond_description() {
        if ( this.condominium ) {
          return this.condominium.descricao
        }
        return ''
    },

    whatsapp() {
        if ( this.condominium && typeof this.condominium['whatsapp_form'] != 'undefined' && this.condominium['whatsapp_form'] ) {
          try {
            return this.condominium['whatsapp_form'].replace(/\D/g,'')
          } catch (error) {
            return this.condominium['whatsapp_form'].toString().replace(/\D/g,'')
          }
        }
        return null
    },

    email() {
        if ( this.condominium && typeof this.condominium['email_form'] != 'undefined' && this.condominium['email_form']  ) {
          return this.condominium['email_form']
        }
        return null
    },

    phone() {
        if ( this.condominium && typeof this.condominium['telefone_form'] != 'undefined' && this.condominium['telefone_form']  ) {
           try {
              return this.condominium['telefone_form'].replace(/\D/g,'')
            } catch (error) {
              return this.condominium['telefone_form'].toString().replace(/\D/g,'')
            }
        }
        return null
    },

    cond_subdescription() {
        if ( this.condominium ) {
          return `Veja as unidades disponíveis no melhor condomínio do bairro ${this.condominium.bairro}!`
        }
        return ''
    },

    differences_list() {
        if ( this.condominium ) {
          return this.condominium['differences']
        }
        return []
    },

    highlights() {

      let dormitorios = '0 Dormitórios'
      let total_unidades = '0 Unidades'
      let area_privativa = '- m²'
      let vagas = '0 Vagas'
      let suites = '0 Suítes'


      if ( this.condominium ) {
        dormitorios = this.condominium['max_dormitorios'] > 1 ? `${this.condominium['max_dormitorios']} Dormitórios` : `${this.condominium['max_dormitorios']} Dormitório`
        vagas = this.condominium['max_vagas'] > 1 ? `${this.condominium['max_vagas']} Vagas` : `${this.condominium['max_vagas']} Vaga`
        total_unidades = this.condominium.totalunid > 1 ? `${this.condominium.totalunid} Unidades` : `${this.condominium.totalunid} Unidade`
      }

      if ( this.available_units.length > 0 ) {
        let area = Math.max.apply(Math, this.available_units.map(function(o) { return o.area_total; }))
        area_privativa = `${area} m²`
      }

      var list = [
        {
          'img': require("@/assets/img/condominio/cama.webp"),
          'label': this.__('Unidades de'),
          'value': dormitorios
        },
        {
          'img': require("@/assets/img/condominio/predio.webp"),
          'label': this.__('Disponível'),
          'value': total_unidades
        },
        {
          'img': require("@/assets/img/condominio/metragem.webp"),
          'label': this.__('Área Privativa'),
          'value': area_privativa
        },
        {
          'img': require("@/assets/img/condominio/carro.webp"),
          'label': this.__('Garagem'),
          'value': vagas
        },
        // {
        //   'img': require("@/assets/img/condominio/banheira.webp"),
        //   'label': this.__('Unidades com'),
        //   'value': suites
        // }
      ]

      return list

    },

    gallery() {

      if ( this.condominium ) {
        return this.condominium.popup_fotos.Foto.map(item => {
          return {"img": item['URLArquivo'][0]}
        })
      }

      return []
    },

    others_condominiums() {

      if ( this.condominiums_arround.length > 0 ) {

        return this.condominiums_arround.map(i => {
          let data = {}
          data['district'] = i['bairro']
          data['name'] = i['nome']
          data['address'] = i['endereco']
          data['link'] = `/condominio/${i['slug']}`
          return data
        })
      }
      return []
    },

    highlighted_image() {
      if ( this.condominium ) {
        let highlighted_image = this.condominium.popup_fotos.Foto.filter(item => item.Principal[0] == '1')

        if ( highlighted_image.length > 0 ) {
          return highlighted_image[0]['URLArquivo']['0']
        } else {
          return this.condominium.popup_fotos.Foto[0]['URLArquivo']['0']
        }

      }
      return  ''
    },

    slideSettings() {
      let settings = {
        "dots": false,
        "arrows": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "centerPadding": "20px",
      }

      if (this.isMobile) {
        settings['slidesToShow'] = 1
        settings['dots'] = true
      }

      return settings
    },

    othersCondSlideSettings() {
      let settings = {
        "dots": false,
        "focusOnSelect": false,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 3,
        "slidesToScroll": 3,
        "touchThreshold": 5
      }

      if (this.isMobile) {
        settings['slidesToShow'] = 1
        settings['dots'] = false
      }

      return settings
    }

  },
  methods: {
    async send(e) {

            if (!await this.validate())
                return false
            
  
            this.contact.to = this.email
            this.contact._recaptcha_token = await this.createRecaptchaToken('CONTACT_PROPERTY')

            let data_contact = {
                "subject": `Contato do Site - Condomínios - Enviado por ${this.contact.fullname}`,
                "to": this.email,
                "fields": [
                    {
                        "label": "Condomínio",
                        "value": this.cond_name,
                    },
                    {
                        "label": "Link",
                        "value": window.location.href,
                    },
                    {
                        "label": "Nome Completo",
                        "value": this.contact.fullname,
                    },
                    {
                        "label": "Telefone",
                        "value": this.contact.fone,
                    },
                    {
                        "label": "E-mail",
                        "value": this.contact.email,
                    }
                ],
                "register_lead": true,
                "_recaptcha_token": await this.createRecaptchaToken('CONTACT')
            }

            this.sending = true
            this.inTransaction = true

            await ContactService.sendGenericContact(data_contact)
            .then(
                response => {
                    this.contact.fullname = ''
                    this.contact.email = ''
                    this.contact.fone = ''
                    this.sendSuccess = true
                }
            )
            .catch(
                error => {
                    this.sendFail = true
                }
            )
            .finally(
                response => {
                    this.inTransaction = true
                    this.sending = false
                    setTimeout(()=>{
                        this.inTransaction = false
                        this.sendFail = false
                        this.sendSuccess = false
                    },5000)
                }
            )

    },

    async validate() {

      var validate = true
      this.validations.fullname = true;
      this.validations.fone = true;
      this.validations.email = true;
      this.validations.email = true;

      if (this.contact.fullname == ''){
          this.validations.fullname = false; validate = false
      }

      if (this.contact.fone == '') {
          this.validations.fone = false; validate = false
      }

      if (this.contact.email == ''){
          this.validations.email = false; validate = false
      }

      if (this.contact.email != '' && !await this.validateEmail() ) {
          this.validations.email = false; validate = false
      }

      return validate
    },

    async validateEmail() {
      return this.contact.email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    },

    openWhatsapp() {
        var text = this.__("Olá preciso de informações sobre esse imóvel: ") +  window.location.href;
        let url = 'https://api.whatsapp.com/send?phone=+55'+ this.whatsapp.replace(/\D/g,'') +'&text=' + encodeURIComponent(text)
        window.open(
            url,
            '_blank'
        );
    },

    openPhone() {
      this.show_phone = true
      if ( this.isMobile ) {
          window.location.href = 'tel:' + this.phone.replace(/\D/g,'');
      }
    },

    goToForm() {
      document.querySelector('.schedule-visit').scrollIntoView({
          behavior: 'smooth'
      });
    },

    OpenTab(url) {
      window.open(
            url,
            '_blank'
        );
    }

  },
  mounted() {
    this.loadRecaptchaTag()
    this.slug = this.$route.params.nomecondominio
    PropertiesService.getCondominiumBySlug(this.slug).then(response => {
      if (response.success) {
        this.condominium = response.data

        let search_filters = {}
        let [address, address_number_tmp, city_tmp] = this.condominium.endereco.split(',')
        let [city, uf] = city_tmp.split('-')
        let [number, district] = address_number_tmp.split('-')

        search_filters['city'] = city.trim()
        search_filters['address'] = address.trim()
        search_filters['address_number'] = number.trim()
        search_filters['size'] = 100
        search_filters['fields'] = ['IsFeiraoApolar','nomeEvento','tipo','transacao','finalidade','cidade','bairro','referencia','Quartos','condominio','garagem','dormitorios','areaterreno','area_total','banheiro','ValorAnterior','valor_considerado', 'vlrcondominio','situacao','FimPromocao','foto_principal','endereco','linksite','Selo','finalidade','popup_fotos', 'bairro_list', 'area_info', 'numero', 'suites']

        PropertiesService.search(search_filters, 'main').then(response => {
          this.available_units = response.data
        })


        let search_arround = {
          'city': city.trim(),
          'district': this.condominium.bairro.trim()
        }
        PropertiesService.getCondominiumsArround(search_arround).then(response => {
          if (response.success) {
            this.condominiums_arround = response.data
          }
        })

      }
    })
    
  }
};
</script>
