<template>
    <div class="customer-area-options">
        <i class="fa fa-caret-up" aria-hidden="true"></i>
        <ul>
            <li v-for="menu,key in menu_options" v-bind:key="key">
                <a :href="menu.link">{{menu.label}}</a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
        menu_options: [
            {"link":"https://www.apolar.com.br/areacliente/", "label":"Inquilino"},
            {"link":"https://www.apolar.com.br/areacliente/", "label":"Proprietário"},
        ]
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
<style>
</style>