<template>
    <div class="contact-form-component">
        <h2>{{ __(title) }}</h2>

        <div v-if="send_status == 'sending'" class="send-proccess">
            <bounce-loader :color="'#FFC20F'"></bounce-loader>
        </div>

        <div v-if="send_status == 'sended'" class="send-proccess">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            {{ __('Contato enviado!') }}
        </div>

        <div>
            <div class="form-input" :class="typeof this.errors['name'] != 'undefined' ? 'error' : ''">
                <label class="label-input" for="">
                    Qual seu nome?
                </label>
                <input type="text" :placeholder="__('Apolino Apolar')" v-model="contact.name">
            </div>

            <div class="form-input">
                <label class="label-input" for="">
                    Esse imóvel está para:
                </label>
                <div class="select-business-box">
                    <div class="vender" :class="{ active: contact.option == 'Venda' }"
                        @click="contact.option = 'Venda'">
                        <span>
                            Vender
                        </span>
                    </div>
                    <div class="alugar" :class="{ active: contact.option == 'Aluguel' }"
                        @click="contact.option = 'Aluguel'">
                        <span>
                            Alugar
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12">
                    <label class="label-input" for="">
                        Qual o tipo desse imóvel?
                    </label>
                    <div class="business-visual-types">
                        <div v-for="type, idx in visual_types" v-bind:key="`visual-type-${idx}`" class="visual-type"
                            :class="getVisualTypeClass(type)" v-on:click="() => { handlePropertyTypeButtons(type.id); }">
                            <img :width="type.size.split('x')[0]" :height="type.size.split('x')[1]" :alt="type.label"
                                :src="type.img" />
                            <span>{{ __(type.label) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-show="!show_other_types && filters.business_subfilter != 'Temporário'"
                class="row trigger-property-types">
                <div class="col-md-12">
                    <div @click="() => { show_other_types = !show_other_types }" class="others-properties-types">
                        <div class="indique-other-types-title">
                            <span>
                                Outros tipos de imoveis
                            </span> 
                            <div class="dropdown-icon"></div>
                        </div>
                    </div>
                </div>
            </div> -->

            <div v-show="!filter_by_ref && show_other_types" class="row property-types-wrapper">
                <div class="col-md-12">
                    <div class="input">
                        <label class="use_icon house label-input" for="">
                            <span class="text">{{ __("Tipo de imóveis:") }}</span>
                        </label>

                        <VSelectMultipleVue :placeholder="__('Selecione o tipo de imóvel')"
                            v-model="filters.property_type" :options="property_type_purpose"
                            v-on:input="changePropertyTypeCombo()" />
                    </div>
                </div>
            </div>
            <div @click="send" class="send-contact-indique">
                <span>Indicar imóvel</span><img :src="require(`@/assets/img/indique/small-arrow-right.webp`)" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import * as yup from "yup";
import { mask } from 'vue-the-mask'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import ContactService from "../../../../services/ContactService";
import PropertiesService from "../../../../services/PropertiesService";

import VSelectMultipleVue from '../VSelectMultiple.vue';

const validateSchema = yup.object({
    name: yup.string().required("Nome completo é obrigatório.")
})

export default {
    directives: { mask },
    components: {
        BounceLoader,
        VSelectMultipleVue
    },
    props: {
        title: {
            type: String,
            default() {
                return "INDIQUE UM IMÓVEL JÁ"
            }
        },
        send_to: {
            type: String,
            default() {
                return "apolar@apolar.com.br"
            }
        },
    },
    data() {
        return {
            show_other_types: false,
            errors: {},
            contact: {
                name: '',
                option: 'Venda',
                type: ''
            },
            selected_business: 'Vender',
            filters: {
                business: "Vendas", //ok
                business_subfilter: "Residencial", //ok
                reference: null, //ok
                city: null, //ok
                district: [], //ok
                property_type: [], //ok
                property_type_combo: [], // ok
                bedrooms: [], //ok
                garage: [], //ok
                price_max: null, //ok
                price_min: null, //ok
                address: null, //ok
                address_number: null,
                open_search: '',//ok
                in_condominium: false // ok
            },
            filters_options: {
                sub_filters: {
                    "Vendas": [
                        {
                            "id": "",
                            "label": "Residencial",
                            "types": [
                                { "id": "Apartamento", "label": "Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63" },
                                { "id": "Casa|Sobrado", "label": "Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53" },
                                { "id": "Studio|Kitnet", "label": "Studio e kitnet", "img": require("@/assets/img/home/studio.webp"), "size": "63x63" },
                                { "id": "Terreno", "label": "Terreno", "img": require("@/assets/img/home/terreno.webp"), "size": "51x51" }
                            ]
                        },
                        {
                            "id": "",
                            "label": "Comercial",
                            "types": [
                                { "id": "Sala", "label": "Sala", "img": require("@/assets/img/home/sala-predio.webp"), "size": "62x63" },
                                { "id": "Loja", "label": "Loja", "img": require("@/assets/img/home/loja.webp"), "size": "56x56" },
                                { "id": "Casa|Sobrado", "label": "Casa comercial", "img": require("@/assets/img/home/casa-comercial.webp"), "size": "61x60" },
                                { "id": "Barracão", "label": "Barracão", "img": require("@/assets/img/home/barracao.webp"), "size": "56x56" }
                            ]
                        },
                        {
                            "id": "",
                            "label": "Lançamentos",
                            "types": [
                                { "id": "Apartamento", "label": "Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63" },
                                { "id": "Casa|Sobrado", "label": "Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53" },
                                { "id": "Studio", "label": "Studio", "img": require("@/assets/img/home/studio.webp"), "size": "63x63" },
                                { "id": "Comercial", "label": "Comercial", "img": require("@/assets/img/home/loja.webp"), "size": "56x56" }
                            ]
                        },
                        {
                            "id": "",
                            "label": "Triple A",
                            "types": [
                                { "id": "Apartamento", "label": "Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63" },
                                { "id": "Casa|Sobrado", "label": "Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53" },
                                { "id": "Studio", "label": "Studio", "img": require("@/assets/img/home/studio.webp"), "size": "63x63" },
                                { "id": "Comercial", "label": "Comercial", "img": require("@/assets/img/home/loja.webp"), "size": "56x56" }
                            ]
                        }
                    ],
                    "Locacao": [
                        {
                            "id": "",
                            "label": "Mensal",
                            "types": [
                                { "id": "Apartamento", "label": "Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63" },
                                { "id": "Casa|Sobrado", "label": "Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53" },
                                { "id": "Studio|Kitnet", "label": "Studio e kitnet", "img": require("@/assets/img/home/studio.webp"), "size": "63x63" },
                                { "id": "Terreno", "label": "Terreno", "img": require("@/assets/img/home/terreno.webp"), "size": "51x51" }
                            ]
                        },
                        {
                            "id": "",
                            "label": "Comercial",
                            "types": [
                                { "id": "Sala", "label": "Sala", "img": require("@/assets/img/home/sala-predio.webp"), "size": "62x63" },
                                { "id": "Loja", "label": "Loja", "img": require("@/assets/img/home/loja.webp"), "size": "56x56" },
                                { "id": "Casa|Sobrado", "label": "Casa comercial", "img": require("@/assets/img/home/casa-comercial.webp"), "size": "61x60" },
                                { "id": "Barracão", "label": "Barracão", "img": require("@/assets/img/home/barracao.webp"), "size": "56x56" }
                            ]
                        },
                        {
                            "id": "",
                            "label": "Temporário",
                            "types": [
                                { "id": "Apartamento", "label": "Apartamento", "img": require("@/assets/img/home/apartamento.webp"), "size": "63x63" },
                                { "id": "Studio", "label": "Studio", "img": require("@/assets/img/home/studio.webp"), "size": "63x63" },
                                { "id": "Casa|Sobrado", "label": "Casa e sobrado", "img": require("@/assets/img/home/casa-sobrado.webp"), "size": "53x53" },
                                { "id": "Outros", "label": "Outros imóveis", "img": require("@/assets/img/home/terreno.webp"), "size": "51x51" }
                            ]
                        },
                    ]
                },
                cities: [],
                types: [],
                references: [],
                adresses: [],
                open_search: []
            },


        };
    },
    computed: {
        visual_types() {
            if (this.filters.business && this.filters.business_subfilter) {
                let sub_filters = this.filters_options.sub_filters[this.filters.business]
                let sub_filter = sub_filters.filter(sub => sub.label == this.filters.business_subfilter)
                return sub_filter.length > 0 ? sub_filter[0]['types'] : null
            }
            return null
        },
        property_type_purpose() {
            let comercial_types = ['Barracão', 'Conjunto', 'Hotel', 'Loja', 'Ponto', 'Prédio', 'Sala', 'Sobreloja', 'Área', 'Casa', 'Terreno']
            if (this.filters.business_subfilter == "Comercial") {
                return comercial_types.sort(function (a, b) {
                    return a.localeCompare(b);
                })
            }

            let residencial_types = this.filters_options.types.filter(type => comercial_types.indexOf(type) == -1)
            residencial_types = residencial_types.concat('Área', 'Casa', 'Terreno')
            return residencial_types.sort(function (a, b) {
                return a.localeCompare(b);
            })
        },
    },
    methods: {
        send() {

            this.errors = {}

    
            validateSchema
                .validate(this.contact, { abortEarly: false })
                .then(async () => {

                    window.open(`https://indica.apolar.net/?nome=${this.contact['name']}&negocio=${this.contact['option']}&tipo=${this.contact['type']}&sign_user=yes`, '_blank');

                    setTimeout(() => {
                        Object.keys(this.contact).forEach((field) => {
                            this.contact[field] = ''
                            this.filters.property_type_combo = []
                        })
                        this.contact['option'] = 'Venda'
                    }, 2000)

                })
                .catch(err => {
                    if (typeof err.inner != "undefined") {
                        err.inner.forEach(error => {
                            Vue.set(this.errors, error.path, error.message)
                        });
                    }
                })

        },
        getVisualTypeClass(type) {
            let _class = `visual-type-${this.slugify(type.id)} `
            if (type.id == "Outros") {
                _class += `trigger-property-types `
            }
            _class += `${this.filters.property_type_combo.indexOf(type.id) != -1 ? 'active' : ''}`
            return _class
        },
        handlePropertyTypeButtons(type) {

            if (type == "Outros") {
                this.show_other_types = !this.show_other_types
                return false
            }

            // let type_index = this.filters.property_type_combo.indexOf(type)

            // if (type_index == -1) {
            //     this.filters.property_type_combo.push(type)

            //     this.filters.property_type_combo.forEach((propType) => {
            //         let types = propType.split("|")
            //         types.forEach((type) => {
            //             if (this.filters.property_type.indexOf(type) == -1 && type != 'Condominio') {
            //                 this.filters.property_type.push(type)
            //             }
            //         })
            //     })

            // } else {
            //     this.filters.property_type_combo.splice(type_index, 1)
            //     type.split("|").forEach((sub_type) => {
            //         let sub_type_idx = this.filters.property_type.indexOf(sub_type)
            //         if (sub_type_idx != -1) {
            //             this.filters.property_type.splice(sub_type_idx, 1)
            //         }
            //     })
            // }

            // this.filters.in_condominium = false
            // if (this.filters.property_type_combo.indexOf("Condominio") != -1) {
            //     this.filters.in_condominium = true
            // }

            this.filters.property_type_combo = [type]
            this.contact.type = type

        },
        
        changePropertyTypeCombo() {

            let possible_types = []
            let possible_types_vendas = this.filters_options['sub_filters']['Vendas'].map(item => item['types'].map(sub => sub.id))
            let possible_types_locacao = this.filters_options['sub_filters']['Locacao'].map(item => item['types'].map(sub => sub.id))
            possible_types_vendas.forEach((list_types) => { possible_types = possible_types.concat(list_types) })
            possible_types_locacao.forEach((list_types) => { possible_types = possible_types.concat(list_types) })
            possible_types = possible_types.filter(type => type != 'Casa' && type != 'Studio')
            possible_types = [...new Set(possible_types)]


            this.filters.property_type.forEach((type) => {
                let possible_type_idx = possible_types.findIndex(p_type => p_type.indexOf(type) != -1)
                let possible_type = possible_types[possible_type_idx]

                if (possible_type != -1 && this.filters.property_type_combo.indexOf(possible_type) == -1) {
                    this.filters.property_type_combo.push(possible_type)
                }
            })

            this.filters.property_type_combo.forEach((propType, idx) => {
                if (typeof propType != "undefined") {
                    let type_changed = this.filters.property_type.findIndex(type => propType.indexOf(type) != -1)
                    console.log(propType, idx, type_changed)
                    if (type_changed == -1) {
                        this.filters.property_type_combo.splice(idx, 1)
                    }
                }
            })

        },
    },
    mounted() {
        this.loadRecaptchaTag()
        PropertiesService.getFilters('main')
    .then(
        response => {
            //this.filters_options.cities = response.data.cities
            this.filters_options.types = response.data.types
            console.log(this.filters_options.types)
            this.filters_options.references = response.data.referencias
            this.filters_options.adresses = response.data.enderecos

            let cities = []
            let districts = []
            let address = []

            //adjusted to place curitiba on first position
            let curitiba_obj = response.data.cities.filter(city_obj => city_obj.city == 'Curitiba')
            let other_cities = response.data.cities.filter(city_obj => city_obj.city != 'Curitiba')
            this.filters_options.cities = curitiba_obj.concat(other_cities)

            this.filters_options.cities.forEach((city)=>{
                cities.push(city.city)
                city.districts.forEach((district)=>{
                    districts.push(`${district}, ${city.city}`)
                })
            })

            response.data.enderecos.forEach((addrs)=>{
                let _address_without_number = `${addrs.address}, ${addrs.district}, ${addrs.city}`
                let _address_with_number = `${addrs.address}, ${addrs.number}, ${addrs.district}, ${addrs.city}`

                if ( address.indexOf(_address_without_number) == -1 ) {
                    address.push(_address_without_number)
                }

                if ( address.indexOf(_address_with_number) == -1 ) {
                    address.push(_address_with_number)
                }
            })

            this.filters_options.open_search = cities.concat(districts, address, this.filters_options.references)
        }
    )
    }
}
</script>
<style>
</style>
