
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

import { v4 as uuidv4, v6 as uuidv6 } from 'uuid';
import ApolarEventService from "./services/ApolarEventService";
import utils from "./utils";
import Lang from "./lang";
import masker from 'vue-the-mask/src/masker.js';
import tokens from 'vue-the-mask/src/tokens';

const GlobalDirectives = {
  install(Vue) {
    
    Vue.mixin({
        data: function() {
          let isMobile = window.innerWidth < 960
          return {
            checkIsMobile: isMobile,
            checkIsSmallScreen: false,
            langClass: null,
            lang: 'ptBR',
            data_index: 'main'
          }
        },
        computed: {
            isMobile: function () {
              return this.checkIsMobile
            },
            isSmallScreen: function() {
              return this.checkIsSmallScreen
            },
            baseUrl: function() {
              let {protocol, host} = window.location
              return `${protocol}//${host}`
            }
        },
        created() {
          if ( window.location.href.indexOf('apolar.com.py') != -1 ) {
            this.lang = 'es'
            this.data_index = 'py'
          }
          // this.lang = 'es'
          // this.data_index = 'py'

          this.langClass = new Lang(this.lang)
        },
        mounted() {
            this.onResize()
            window.addEventListener('resize', this.onResize, { passive: true })
        },
        methods: {
            getClientSessionNav() {
              let client_session_nav = localStorage.getItem("client_session_nav")
              if (!client_session_nav) {
                client_session_nav = uuidv4()
                localStorage.setItem("client_session_nav", client_session_nav);
              }
              return client_session_nav
            },
            onResize () {
                this.checkIsMobile = window.innerWidth <= 900
                this.checkIsSmallScreen = window.innerWidth < 1400
            },
            strToFloat(str) {
              if (!str) {
                return 0
              }
              return parseFloat(str.replace(/[^\d,.-]/g, '').replaceAll('.','').replaceAll(',','.'))
            },
            slugify(text) {
              return utils.slugify(text)
            },
            capitalize(str) {
              return str[0].toUpperCase() + str.slice(1);
            },

            get_property_url(property) {
              return utils.get_property_url(property)
            },

            register_apolar_event(eventType, eventSource, eventAttrs) {
              console.log(`Event: ${eventType} - Source: ${eventSource} - Attr: ${JSON.stringify(eventAttrs)}`)

              let date = new Date();
              let formatted_date =  date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

              let data = {
                "client_session_nav": this.getClientSessionNav(),
                "eventType": eventType,
                "eventSource": eventSource,
                "eventAttrs": eventAttrs,
                "eventDate": formatted_date
              }
              ApolarEventService.registerEvent(data)
            },

            number_format: function(number, decimals, decPoint, thousandsSep) {
                number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
                var n = !isFinite(+number) ? 0 : +number
                var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
                var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
                var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
                var s = ''
    
                var toFixedFix = function (n, prec) {
                    if (('' + n).indexOf('e') === -1) {
                    return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
                    } else {
                    var arr = ('' + n).split('e')
                    var sig = ''
                    if (+arr[1] + prec > 0) {
                        sig = '+'
                    }
                    return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
                    }
                }
    
                // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
                s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
                if (s[0].length > 3) {
                    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
                }
                if ((s[1] || '').length < prec) {
                    s[1] = s[1] || ''
                    s[1] += new Array(prec - s[1].length + 1).join('0')
                }
    
                return s.join(dec)
            },

            __:function(str_id, pattern = null) {
              if (!pattern) {
                return this.langClass.trans(str_id)
              } else {
                return this.langClass.pattern_trans(str_id, pattern)
              }
            },

            async createRecaptchaToken(action='CONTACT') {
              return new Promise(async (resolve, reject) => {
                const token = await grecaptcha.enterprise.execute('6LcQhR0pAAAAAP63Ahgn9ERh0kn2c2bzd7iZPHgj', {action: action});
                resolve(token)
              })
            },

            useMask: (value, mask) => {
              try { value = value.toString() } catch (error) {}
              return masker(value, mask, true, tokens)
            },

            loadRecaptchaTag() {
              let head = document.querySelector('head')
              let recaptcha_script = document.createElement("script")
              recaptcha_script.src=`https://www.google.com/recaptcha/enterprise.js?render=6LcQhR0pAAAAAP63Ahgn9ERh0kn2c2bzd7iZPHgj`
              recaptcha_script.async=true
              recaptcha_script.defer=true
              head.append(recaptcha_script)
            }
        }
    })

  }
};

export default GlobalDirectives;
