<template>
    <div v-if="open" class="form-lancamentos-component">
        <div class="form-lancamentos-backdrop">
            <div class="form-lancamentos-form">
                <div v-on:click="closePopup" class="close-button">(X)</div>
                <h1>Fale Conosco</h1>
                <span class="description">Receba informações sobre o empreendimento.</span>
                <div class="messages">
                    <div v-if="send_success" class="success">Contato enviado com sucesso!</div>
                    <div v-if="send_fail" class="fail">Ops! Houve um problema ao enviar seu contato.</div>
                </div>

                <form @submit.prevent="send">
                    <div class="input-wrapper">
                        <input 
                            :class="typeof this.errors['fullname'] != 'undefined' ? 'error': ''"
                            v-model="contact.fullname" 
                            name="full_name" 
                            placeholder="Nome completo" type="text">
                        <!-- <FormValidationMessage :errors="this.errors" :field="'fullname'" /> -->
                    </div>

                    <div class="input-wrapper">
                        <input 
                            :class="typeof this.errors['phone'] != 'undefined' ? 'error': ''"
                            v-model="contact.phone" 
                            name="fone" 
                            placeholder="Telefone com DDD" type="text"
                            v-mask="'(##) #####-####'" 
                        >
                        <!-- <FormValidationMessage :errors="this.errors" :field="'phone'" /> -->
                    </div>

                    <div class="input-wrapper">
                        <input 
                            :class="typeof this.errors['email'] != 'undefined' ? 'error': ''"
                            v-model="contact.email" 
                            name="email" 
                            placeholder="E-mail" 
                            type="text">
                        <!-- <FormValidationMessage :errors="this.errors" :field="'email'" /> -->
                    </div>

                    <div v-if="immobile_name != 'Lançamento Alphaville'"  class="input-wrapper">
                        <select 
                            :class="typeof this.errors['units'] != 'undefined' ? 'error': ''"
                            v-model="contact.units" 
                            name="units" 
                            id="" 
                            class="no-apply-select2">
                            <option value="">Unidades de interesse</option>
                            <option value="1 dormitório">1 dormitório</option>
                            <option value="2 dormitórios">2 dormitórios</option>
                            <option value="3 dormitórios">3 dormitórios</option>
                            <option value="4 dormitórios">4 dormitórios</option>
                        </select>
                        <!-- <FormValidationMessage :errors="this.errors" :field="'units'" /> -->
                    </div>

                    <div class="input-wrapper">
                        <input 
                            v-model="contact.accept" 
                            id="accept" 
                            type="checkbox" 
                            name="accept">
                        <label for="accept">Eu concordo em receber comunicações</label>
                        <div class="clear"></div>
                    </div>

                    <span class="small">
                        Seus dados estarão seguros e nos comprometemos em não utilizar suas informações para enviar qualquer tipo de SPAM.
                    </span>

                    <button type="submit" class="send">{{send_button}}</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue"
import {mask} from 'vue-the-mask'
import * as yup from "yup";
import FormValidationMessage from "./form-validation-message.vue";
import ContactService from "../../../services/ContactService";


const validateSchema = yup.object({
    fullname: yup.string().required("Nome completo é obrigatório."),
    phone: yup.string().required("Telefone é obrigatório."),
    email: yup.string().required("E-mail é obrigatório.").email("E-mail inválido."),
    units: yup.string().required("Unidade de interesse é obrigatório.")
})


const validateSchemaWithoutUnits = yup.object({
    fullname: yup.string().required("Nome completo é obrigatório."),
    phone: yup.string().required("Telefone é obrigatório."),
    email: yup.string().required("E-mail é obrigatório.").email("E-mail inválido.")
})



export default {
  directives: {mask},
  components: {FormValidationMessage},
  props: {
    immobile_name: {
      type: String,
      default() {
        return ""
      }
    },
    open: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
        contact: {
            fullname:'',
            phone:'',
            email:'',
            units:'',
            accept:'',
        },
        send_button: 'ENVIAR',
        send_success: false,
        send_fail: false,
        errors: {}
    };
  },
  methods: {
      closePopup() {
        this.$emit("update:open", false)
      },
      send() {
        Vue.set(this, 'errors', {})

        var useValidateSchema = this.immobile_name == 'Lançamento Alphaville' ? validateSchemaWithoutUnits : validateSchema

        useValidateSchema
            .validate(this.contact, {abortEarly: false})
            .then(()=>{
                this.errors = {}
                
                this.send_button = "Enviando..."

                let data_contact = {
                    "subject": `${this.immobile_name} - Contato de ${this.contact.fullname}`,
                    "to": "apolar@apolar.com.br",
                    "fields": [
                        {
                            "label": "Nome Completo",
                            "value": this.contact.fullname,
                        },
                        {
                            "label": "Telefone",
                            "value": this.contact.phone,
                        },
                        {
                            "label": "E-mail",
                            "value": this.contact.email,
                        },
                        {
                            "label": "Unidades de Interesse",
                            "value": this.contact.units,
                        },
                        {
                            "label": "Aceita receber comunicados?",
                            "value": (this.contact.accept ? "Sim" : "Não"),
                        }
                    ]
                }

                ContactService.sendGenericContact(data_contact)
                .then((reponse)=>{
                    this.send_success = true
                })
                .catch((error)=>{
                    this.send_fail = true
                })
                .finally(()=>{
                    
                    this.send_button = "ENVIAR"

                    setTimeout(()=>{
                        this.send_fail = false
                        this.send_success = false
                        this.closePopup()

                        Object.keys(this.contact).forEach((field)=>{
                            this.contact[field] = ''
                        })

                    },4000)
                })
                
                
            })
            .catch(err => {
                if ( typeof err.inner != "undefined" ) {
                    err.inner.forEach(error => {
                        Vue.set(this.errors, error.path, error.message)
                    });
                }
            })
      }
  },
  mounted() {
  }
};
</script>
<style>
</style>