<template>
    <div>
        <div class="property-details-form">
            
            <div class="price-box" :class="immobile.IsFeiraoApolar == 1 ? 'feirao-apolar' : ''">

                <!-- <div class="price-label">A partir de</div> -->
                <div v-if="immobile.IsFeiraoApolar && old_price" class="price-old">
                    {{currency}}{{old_price}}
                </div>
                <div class="price-new">
                    {{currency}}{{number_format(immobile.valor_considerado,2,',','.')}}
                    <span v-if="immobile.transacao == 'Locacao'" >/mês <div v-on:click="toogleAlert('aluguel')" class="info-button-icon"></div></span>
                </div>
                <div class="price-others">
                    {{__('Outros valores')}}
                </div>
                <ul class="price-others-values">
                    <li v-if="parseFloat(immobile.vlrcondominio) > 0"> {{__('Condomínio')}} {{currency}}{{number_format(immobile.vlrcondominio,2,',','.')}} <div v-on:click="toogleAlert('condominio')" class="info-button-icon"></div></li>
                    <li v-if="parseFloat(immobile.iptu) > 0"> {{__('IPTU')}} {{currency}}{{number_format(immobile.iptu,2,',','.')}} <div v-on:click="toogleAlert('iptu')" class="info-button-icon"></div></li>
                    <li v-if="parseFloat(immobile.seguro) > 0"> {{__('Seguro Incêndio')}} {{currency}}{{number_format(immobile.seguro,2,',','.')}} <div v-on:click="toogleAlert('seguro-incendio')" class="info-button-icon"></div></li>
                    <li v-if="immobile.transacao == 'Locacao'" class="total-rent"> {{__('Total')}} {{currency}}{{number_format(immobile.valor_total,2,',','.')}} <div v-on:click="toogleAlert('total')" class="info-button-icon"></div></li>
                </ul>

            </div>

            <div class="form-title">{{__('Gostou deste imóvel?')}}</div>
            <div class="form-subtitle">{{__('Preencha seus dados abaixo e entraremos em contato')}}</div>

            <div v-if="sending" class="send-message">
                <bounce-loader :color="'#FFC20F'"></bounce-loader>
            </div>

            <div v-show="inTransaction && sendSuccess" class="contact-send success">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                {{__("Contato enviado!")}}
            </div>

            <div v-show="inTransaction && sendFail" class="contact-send fail">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                {{__("Houve uma falha ao tentar enviar seu contato.")}}
            </div>

            <form v-if="!inTransaction" action="">
                <div class="row">
                    <div class="col-md-12">
                        <label for="">{{__("Nome")}}</label>
                        <input 
                            type="text" 
                            v-model="contact.fullname" 
                            class="form-control"
                            :class="!this.validations.fullname ? 'error' : ''"
                            :placeholder="__('Nome')">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label for="">{{__("E-mail")}}</label>
                        <input 
                            type="email" 
                            v-model="contact.email" 
                            class="form-control"
                            :class="!this.validations.email ? 'error' : ''"
                            :placeholder="__('E-mail')">
                    </div>
                </div>

                <div class="row">
                    <div class="col-8 col-md-8">
                        <label for="">{{__("Telefone")}}</label>
                        <input 
                            type="tel" 
                            v-model="contact.fone" 
                            v-mask="phone_mask" 
                            class="form-control" 
                            :class="!this.validations.fone ? 'error' : ''"
                            :placeholder="__('Telefone')">
                    </div>
                    <div class="col-4 col-md-4">
                        <label for="">&nbsp;</label>
                        <button @click.prevent="send" class="form-control send-button">{{__('Enviar')}}</button>
                    </div>
                </div>
            </form>

            <div class="talk-with-section">
                <div v-if="immobile.lojacelular != ''" v-on:click="openWhatsapp" class="talk-with">
                    <div class="immobile-icons whatsapp"></div>
                    <span>{{__('Fale pelo Whatsapp')}}</span>
                    <div class="clear"></div>
                </div>

                <div v-if="immobile.lojatelefone != ''" v-on:click="openFone" class="talk-with">
                    <div class="immobile-icons fone"></div>
                    <span v-if="showFone == ''" >{{__('Fale pelo Telefone')}}</span>
                    <span v-else >{{showFone}}</span>
                    <div class="clear"></div>
                </div>
            </div>

        </div>

        <div v-if="alert.title" class="toogle-alert-backdrop">
            <div class="toogle-alert-popup">
                <div class="toogle-alert-title">{{alert.title}}</div>
                <div class="toogle-alert-description">{{alert.description}}</div>
                <div class="toogle-alert-ok" @click="()=>{alert.title = ''}">{{__('ENTENDI')}}</div>
            </div>
        </div>

    </div>
</template>
<script>
import Vue from "vue";
import jQuery from "jquery";
import {mask} from 'vue-the-mask'
import ContactService from "../../../services/ContactService"
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'

export default {
  directives: {mask},
  components: {
      BounceLoader
  },
  props: ["immobile"],
  data() {
    return {
        contact: {
            url: "",
            reference: "",
            title: "",
            fullname: '',
            email: '',
            fone: '',
            to:''
        },
        validations: {
            fullname: true,
            email: true,
            fone: true
        },
        showFone: '',
        sending: false,
        inTransaction: false,
        sendSuccess: false,
        sendFail: false,
        alert: {
            title: "",
            description: ""
        },
        enableSohTecButton: false
    };
  },
  computed: {
    apolar_event_data: function() {
      if (Object.keys(this.immobile).length > 0) {
        return {
          "loja": this.immobile.loja,
          "referencia": this.immobile.referencia,
          "transacao": this.immobile.transacao,
          "tipo_imovel": this.immobile.tipo_search,
          "cidade": this.immobile.cidade_search,
          "bairro": this.immobile.bairro_search,
          "area": this.immobile.area_total,
        }
      }
      return null
    },
    old_price() {
        if ( this.immobile.ValorAnterior && this.immobile.ValorAnterior > this.immobile.valor_considerado ) {
            return this.number_format(this.immobile.ValorAnterior, 2, ',', '.')
        }
        return null
    },

    currency() {
        let currency = "R$ "

        if (Object.keys(this.immobile).length > 0) {
            switch(this.immobile.idtipomoeda) {
                case 1:
                    currency = "R$ "
                break;
                case 2:
                    currency = "$ "
                break;
                case 3:
                    currency = "₲ "
                break;
            }
        }

        return currency
    },

    phone_ddi() {
        let ddi = ''
        if (Object.keys(this.immobile).length > 0) {
            ddi = this.data_index == 'py' ? '+595' : '+55'
        }
        return ddi
    },

    phone_mask() {
        let mask = ['(##) #####-####']
        if (Object.keys(this.immobile).length > 0) {
            mask = this.data_index == 'py' ? ['##########'] : mask
        }
        return mask
    }
  },
  methods: {
        async send(e) {

                if (!await this.validate())
                    return false
                
                this.contact.transacao = this.immobile.transacao
                this.contact.loja_codigo = this.immobile.codigoloja
                this.contact.loja_nome = this.immobile.loja
                this.contact.url = window.location.href
                this.contact.reference = this.immobile.referencia
                this.contact.title = this.immobile.condominio
                this.contact.to = this.immobile.lojaemail
                this.contact._recaptcha_token = await this.createRecaptchaToken('CONTACT_PROPERTY')

                this.sending = true
                this.inTransaction = true

                await ContactService.sendPropertyContact(this.contact)
                .then(
                    response => {
                        this.contact.fullname = ''
                        this.contact.email = ''
                        this.contact.fone = ''
                        this.sendSuccess = true
                        this.register_apolar_event('click', 'contato_formulario', this.apolar_event_data)
                    }
                )
                .catch(
                    error => {
                        this.sendFail = true
                    }
                )
                .finally(
                    response => {
                        this.inTransaction = true
                        this.sending = false
                        setTimeout(()=>{
                            this.inTransaction = false
                            this.sendFail = false
                            this.sendSuccess = false
                        },5000)
                    }
                )

        },

        async validate() {

                var validate = true
                this.validations.fullname = true;
                this.validations.fone = true;
                this.validations.email = true;
                this.validations.email = true;

                if (this.contact.fullname == ''){
                    this.validations.fullname = false; validate = false
                }

                if (this.contact.fone == '') {
                    this.validations.fone = false; validate = false
                }

                if (this.contact.email == ''){
                    this.validations.email = false; validate = false
                }

                if (this.contact.email != '' && !await this.validateEmail() ) {
                    this.validations.email = false; validate = false
                }

                return validate
        },

        async validateEmail() {
            return this.contact.email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        },

        openWhatsapp() {
            this.register_apolar_event('click', 'whatsapp', this.apolar_event_data)
            var text = this.__("Olá preciso de informações sobre esse imóvel: ") +  window.location.href;
            window.open(
                'https://api.whatsapp.com/send?phone=' + this.phone_ddi + this.immobile.lojacelular.replace(/\D/g,'') +'&text=' + encodeURIComponent(text),
                '_blank'
            );
        },

        openFone() {
            this.register_apolar_event('click', 'telefone', this.apolar_event_data)
            this.showFone = this.immobile.lojatelefone
            if ( this.isMobile ) {
                window.location.href = 'tel:' + this.immobile.lojatelefone.replace(/\D/g,'');
            }
        },

        toogleEntenda() {

        },

        toogleAlert(alert) {

            switch (alert) {
                case "aluguel":
                    this.alert.title = this.__("Entenda o Valor do Aluguel")
                    this.alert.description = this.__(`O valor do aluguel é R$${this.number_format(this.immobile.valoraluguel, 2, ',','.')} com abatimento de R$${this.number_format(this.immobile.valorabatimento, 2, ',','.')} para pagamento em dia, e com este benefício o aluguel sai por R$${this.number_format(this.immobile.valor_considerado, 2, ',','.')}`, 
                        'O valor do aluguel é %s com abatimento de %s para pagamento em dia, e com este benefício o aluguel sai por %s') + '.'
                    break;

                case "iptu":
                    this.alert.title = this.__("IPTU")
                    this.alert.description = this.__(`É um imposto municipal cujo o valor é determinado pela prefeitura.`)
                    break;

                case "condominio":
                    this.alert.title = this.__("Condominio")
                    this.alert.description = this.__(`Valor sujeito a alteração conforme deliberações condominiais, podendo, ainda, variar de acordo com as despesas fixas e eventuais do condomínio (ex.: água, luz, conservação e manutenção do prédio, entre outros).`)
                    break;

                case "seguro-incendio":
                    this.alert.title = this.__("Seguro incêndio")
                    this.alert.description = this.__(`Inclui proteção obrigatória contra incêndio, queda de raio ou explosão.`)
                    break;

                case "total":
                    this.alert.title = "Total"
                    this.alert.description = this.__("Valor sujeito a alteração.")
                    break;

                default:
                    break;
            }

            console.log(this.alert.title)



        }
  },
  mounted() {

    if ( window.location.href.indexOf("/alugar/") != -1 ) {
        this.enableSohTecButton = true
    }

    this.loadRecaptchaTag()

  },
  watch:{
  }
};
</script>
<style>
</style>