<template>
    <div class="apolar-check-numbers">
        <ul>
            <li v-for="n in range" :data-number="n" v-on:click="check_number(n)" v-bind:key="n" :class="getClass(n)">
                {{label(n)}}
            </li>
        </ul>
    </div>
</template>
<script>

export default {
  components: {},
  props: {
    range: {
      type: Array,
      default() {
        return [1,2,3,4]
      }
    },
    value: {
      type: [Number, Array]
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      }
    },
    all_label_plus: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      seletectValues: []
    };
  },
  methods: {
      check_number(n) {

          if ( this.multiple ) {
            let selected = this.seletectValues.indexOf(n)
            if (selected == -1) {
              this.seletectValues.push(n)
            } else {
              this.seletectValues.splice(selected, 1)
            }

            this.$emit("check", this.seletectValues)
            this.$emit("input", this.seletectValues)

          } else {
            this.seletectValues = [n]
            this.$emit("check", this.seletectValues[0])
            this.$emit("input", this.seletectValues[0])
          }

      },
      label(n) {
          if (!this.all_label_plus) {
            return n == this.range.length ? `+ ${n}` : n
          }
          return `+ ${n}`
      },
      getClass(n) {
        if (this.seletectValues.indexOf(n) != -1) {
          return 'checked'
        }
        return ''
      }
  },
  mounted() {
  },
  watch:{
    value: {
      handler: function(value) {
        if ( this.multiple ) {
          this.seletectValues = value.map(v => parseInt(v) )
        } else {
          value = parseInt(value)
          this.seletectValues = [value]
        }
      },
      deep: true
    }
  }
};
</script>
<style>
</style>