<template>
    <div>
    <div class="box-consortium-component" :class="`layout-${layout}`">
        <img class="consortium-image" src="@/assets/img/components/box-consortium/banner-consortium-image.webp" alt="Consórcio Apolar">
        <div class="consortium-info">
            
            <h3 v-if="layout=='banner'">Que tal considerar uma condição especial para adquirir seu imóvel?</h3>

            <div v-if="layout=='banner'" class="description">
                A Apolar Consórcios viabiliza a aquisição do seu imóvel.<br/>
                Venha se surpreender com as nossas condições!
            </div>


            <h3 v-if="layout=='box'">Cartas Contempladas Apolar</h3>

            <div v-if="layout=='box'" class="description">
                <p>Viabilize a aquisição do seu imóvel, mesmo não averbado.</p>
                <p>Uma forma inteligente para morar ou investir</p>
            </div>

            <div @click="showForm=true" class="button">Fazer uma simulação</div>
        </div>
    </div>

        <consortiumContactFormVue :send_mode="send_mode" :additional_emails="additional_emails" v-if="showForm" v-on:closeForm="()=>{showForm=false}" />
    </div>
</template>
<script>
import Vue from 'vue'
import "@/assets/scss/custom/principal/components/_box_consortium.scss"
import consortiumContactFormVue from './consortium-contact-form.vue'


export default {
  props: {
    send_mode: {
      type: String,
      default() {
        return "default"
      }
    },
    layout: {
      type: String,
      default() {
        return "banner"
      }
    },
    additional_emails: {
      type: Array,
      default() {
        return []
      }
    },
  },
  components: {
    consortiumContactFormVue
  },
  data() {
    return {
      showForm: false
    };
  },
  computed: {
  },
  methods: {
   
  },
  mounted() {
  }
}
</script>
<style>
</style>
