<template>
  <div class="page-lancamentos-search">
    <section class="lancamentos-banner">
      <div class="filter-localities">
        <div class="container shape-container d-flex align-items-center">
          <div class="advanced-filters">
            <div class="immobile-icons advanced-filter"></div>
            <span class="text">FILTROS AVANÇADOS</span>
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <AdvancedSearch></AdvancedSearch>
    </section>

    <section class="result-properties">
        <div class="container shape-container">
            

            <div class="list-properties" :class="show_map ? 'list-map-on': 'list-map-off'">

                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12 p-0">
                      <!-- <button v-on:click="()=>{ show_map = !show_map }" class="toogle-list-map">
                        <span v-if="!show_map" >
                           <i class="fa fa-map-marker" aria-hidden="true"></i>
                            MOSTRAR MAPA
                        </span>
                        <span v-else >
                          <i class="fa fa-align-justify" aria-hidden="true"></i>
                          MOSTRAR LISTA
                        </span>
                      </button> -->

                      <select v-model="order_search" v-on:change="searchOrdered" class="order-search">
                        <option value="">ORDENAR POR</option>
                        <option value="price_asc">Preço (menor - maior)</option>
                        <option value="price_desc">Preço (maior - menor)</option>
                      </select>
                      
                    </div>
                  </div>
                </div>
                
                <template v-for="property,key in properties">
                    <Immobile 
                        v-bind:key="key" 
                        v-bind:property="property"
                        v-bind:layout="'exposed'">
                    </Immobile>
                </template>
                <div class="clear"></div>


                <button class="load-more" v-if="scroll_id != '' && total > properties.length" v-on:click="loadMore" >Carregar mais imóveis</button>
            </div>

            

            <div v-show="show_map" class="map-properties">
                <Openstreet  :refresh="show_map" :properties="map_properties"></Openstreet>
            </div>

            <div class="clear"></div>

        </div>
    </section>

    <section class="footer-banners">

      <carousel class="site-slider" :items=1 :loop=true :autoplay=true :nav=false :dots=false :autoHeight=true>


        <div class="open-form-lancamento">
            <a href="/lancamentos/imovel/curitiba/portao/curitiba-portao-le-monde-202532">
              <img
                v-if="isMobile"
                src="@/assets/img/lancamento-lemonde-mobile.webp"
                alt="Concep Le Monde"
              />
              <img
                v-if="!isMobile"
                src="@/assets/img/lancamento-lemonde-desktop.webp"
                alt="Concep Le Monde"
              />
            </a>
        </div>
        
        <div class="open-form-lancamento" v-on:click="openFormLancamentos('Reserva Barigui')">
            <img
              v-if="isMobile"
              src="@/assets/img/mobile_lancamento1.webp"
              alt="Reserva Barigui"
            />
            <img
              v-if="!isMobile"
              src="@/assets/img/desktop_lancamento1.webp"
              alt="Reserva Barigui"
            />
            <a  v-if="!isMobile" class="show_desktop form-lancamento  lancamento1" target="_blank" href="https://www.apolar.com.br/lancamentos/imovel/curitiba/ecoville/curitiba-ecoville-reserva-barigui-201876"></a>
            <a  v-if="isMobile" class="show_mobile form-lancamento lancamento1" target="_blank" href="https://www.apolar.com.br/lancamentos/imovel/curitiba/ecoville/curitiba-ecoville-reserva-barigui-201876"></a>
        </div>

        <div class="open-form-lancamento" v-on:click="openFormLancamentos('Lançamento Alphaville')">
            <img
              v-if="isMobile"
              src="@/assets/img/mobile_alphaville.webp"
              alt="Lançamento Alphaville"
            />
            <img
              v-if="!isMobile"
              src="@/assets/img/desktop_alphaville.webp"
              alt="Lançamento Alphaville"
            />
        </div>

        <a target="_blank" :href="'https://procura.apolar.com.br/'">
          <img
              v-if="isMobile"
              src="@/assets/img/lancamento_banner_mobile_procura.webp"
              alt="Apolar Procura"
            />
          <img
            v-if="!isMobile"
            src="@/assets/img/lancamento_banner_desktop_procura.webp"
            alt="Apolar Procura"
          />
        </a>

        <div class="open-form-lancamento" v-on:click="openFormLancamentos('Reserva Barigui')">
            <img
              v-if="isMobile"
              src="@/assets/img/mobile_lancamento2.webp"
              alt="Reserva Barigui"
            />
            <img
              v-if="!isMobile"
              src="@/assets/img/desktop_lancamento2.webp"
              alt="Reserva Barigui"
            />

            <a v-if="!isMobile" class="show_desktop form-lancamento  lancamento2" target="_blank" href="https://www.apolar.com.br/lancamentos/imovel/curitiba/ecoville/curitiba-ecoville-reserva-barigui-201876"></a>
            <a v-if="isMobile" class="show_mobile form-lancamento  lancamento2" target="_blank" href="https://www.apolar.com.br/lancamentos/imovel/curitiba/ecoville/curitiba-ecoville-reserva-barigui-201876"></a>
        </div>
      </carousel>

    </section>

    <FormLancamentos 
      :immobile_name="form_lancamentos.immobile_name" 
      :open.sync="form_lancamentos.open" />
  </div>
</template>
<script>
import Vue from "vue"
import AdvancedSearch from "../views/components/apolar/advanced-search.vue";
import jQuery from "jquery";
import Immobile from "./components/apolar/immobile";
import Openstreet from "./components/apolar/openstreet.vue"
import FormLancamentos from "./components/apolar/form-lancamentos.vue";
import carousel from 'vue-owl-carousel'

import PropertiesService from "../services/PropertiesService"

export default {
  name: "PropertyDetails",
  components: {
    AdvancedSearch,
    Immobile,
    Openstreet,
    carousel,
    FormLancamentos
  },
  metaInfo() {
    return {
      title: "Busca de Lançamentos",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:"Apolar Lançamentos"
        }
      ]
    };
  },
  data() {
    return {
        show_map: false,
        properties: [],
        map_properties: [],
        order_search: '',
        scroll_id: '',
        total:0,
        form_lancamentos: {
          open: false,
          immobile_name:''
        }
    };
  },
  computed: {},
  methods: {
      initjQuery() {
          const main_el = jQuery(this.$el)
          const advanced_search_el = main_el.find(".apolar-advanced-search")

          main_el.find(".advanced-filters").on("click", ()=>{
              advanced_search_el.css({"bottom": `-${advanced_search_el.height() + 40}px`})
              advanced_search_el.fadeIn('fast')
          })

          jQuery(window).on('scroll', function(){

            var contentMainBottom = jQuery(".result-properties").offset().top + jQuery(".result-properties").height();
            var mapBottom = jQuery(".openstreet-component").offset().top + jQuery(".openstreet-component").height()
            var footerTop = jQuery(".footer-banners").offset().top

            var mapTop = (jQuery(document).scrollTop() + 170) - jQuery(".openstreet-component").height()
            if ( (jQuery(document).scrollTop() + 30) >= jQuery(".lancamentos-banner").height() ) {

              // if ( mapBottom > contentMainBottom ) {
              //   mapTop = contentMainBottom - (700 * 2)
              // }

              jQuery(".openstreet-component").css({
                top: mapTop
              })
            
            } else {
              jQuery(".openstreet-component").stop().css({
                top: 0
              })
            }

          })
      },


      searchOrdered() {
          this.search(this.$advanced_filters)
      },

      search(filters, append = false) {

        var search_filters = JSON.parse(JSON.stringify(filters))
        search_filters['order'] = this.order_search

        PropertiesService.search(search_filters)
        .then(
          response => {
            var properties = response.data
            if (append) {
              properties = this.properties.concat(response.data)
            }
            Vue.set(this, "properties", properties)
            Vue.set(this, "scroll_id", response.scroll_id)
            Vue.set(this, 'total', response.total)
          }
        )
      },

      searchForMap(filters) {
        
        var map_filters = JSON.parse(JSON.stringify(filters))
        map_filters['size'] = 10000
        map_filters['fields'] = ['referencia','EixoX','EixoY','condominio', 'rua_e_numero', 'cidade', 'bairro', 'foto_principal']
        
        PropertiesService.search(map_filters)
        .then(
          response => {
            var properties = response.data
            Vue.set(this, "map_properties", properties)
          }
        )
      },

      loadMore() {
        let next_page = {"scroll_id": this.scroll_id}
        this.search(next_page, true)
      },

      openFormLancamentos(immobile_name) {
        this.form_lancamentos.immobile_name = immobile_name
        this.form_lancamentos.open = true
      }
  },
  mounted() {
      this.initjQuery()
  },
  created() {
    this.search(this.$advanced_filters)
    this.searchForMap(this.$advanced_filters)
  },
  watch:{
    $advanced_filters: function(filters) {
      this.search(filters)
      this.searchForMap(filters)
    }
  }
};
</script>
