import Amplify, { API } from 'aws-amplify'
//import authHeader from "./AuthHeader"
//import history from "@history.js";
//import jwtAuthService from "./jwtAuthService";

class MainService {

    api_name = null
    api_path = null
    api_request = {}

    constructor() {
        //this.api_request = { headers: authHeader()}
    }

    doRequest = async (method, action, data = {}) => {

        //this.api_request = { headers: authHeader()}

        var request = null

        switch (method) {
            case "get":
                request = this.get(action)
                break;

            case "post":
                request = this.post(action, data)
                break;

            case "del":
                request = this.delete(action)
                break;
        
            default:
                request = this.post(action, data)
                break;
        }

        //this.checkLogged(request)

        return request

    }

    get = async (action) => {
        action = action
        let request = this.api_request
        return API.get(this.api_name, `/${this.api_path}/${action}`, request)
    }

    delete = async(action) => {
        action = action
        let request = this.api_request
        request['body'] = {}
        return API.del(this.api_name, `/${this.api_path}/${action}`, request)
    }

    post = async (action, data, ) => {
        action = action
        let request = this.api_request
        request['body'] = data
        return API.post(this.api_name, `/${this.api_path}/${action}`, request)
    }

    // checkLogged = async (request) => {
    //     request.catch(
    //         response => {
    //             if (typeof response.response != "undefined" && response.response.status == 403) {
    //                 jwtAuthService.logout();
    //                 history.push({
    //                     pathname: "/session/signin"
    //                 });
    //             }              
    //         }
    //     )
    // }
}

export default MainService;