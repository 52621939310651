<template>
  <div class="page-principal-search">

    <section class="result-properties">
        <div class="container shape-container">

            <div class="row title-row">
              <div class="col-12">
                <h1>{{seo_title}}</h1>
              </div>
            </div>
            
            <div class="row">
              <div class="filter-properties">
                <div class="col-12">
                  <div class="use_filter" v-on:click="show_search_form=!show_search_form" > Aplicar filtros </div>
                  <HomeSearch 
                    v-show="show_search_form || !checkIsSmallScreen" 
                    ref="searchbox" 
                    :openAdvanced="open_advanced_mode" 
                    :onlysearch="true"
                    v-on:filterApplied="()=>{checkIsSmallScreen ? show_search_form = false : null }"
                  />
                </div>
              </div>
              <div class="list-properties">

                  <div class="col-md-12 mb-4">
                    <div class="row">
                      <div class="col-md-12 p-0">
                          
                        <select v-if="properties.length > 0" v-model="order_search" v-on:change="searchOrdered" class="order-search">
                          <option value="">ORDENAR POR</option>
                          <option value="price_asc">Preço (menor - maior)</option>
                          <option value="price_desc">Preço (maior - menor)</option>
                        </select>
                        
                      </div>
                    </div>
                  </div>

                   <template  v-if="properties.length > 0">
                      <template v-for="property,key in properties">
                          <property v-bind:key="`prop_${key}`" :preview_images="3" :property="property"></property>
                      </template>
                   </template>
                  
                  <div v-if="properties.length == 0 && !searching" class="no-results-wrapper">
                    <div class="no-results-msg">
                      Não houve resultados para sua busca, trouxemos algumas opções semelhantes.
                    </div>
                    <template v-for="property,key in similar_properties">
                        <property v-bind:key="`prop_${key}`" :preview_images="3" :property="property"></property>
                    </template>
                  </div>

                  <div class="clear"></div>

                  <button class="load-more" v-if="scroll_id != '' && total > properties.length" v-on:click="loadMore" >Carregar mais imóveis</button>
              </div>
            </div>

        </div>
    </section>

  </div>
</template>
<script>
import Vue from "vue"
import jQuery from "jquery";
import PropertiesService from "../../services/PropertiesService"
import HomeSearch from "../components/apolar/principal/home-search.vue"
import property from '../components/apolar/principal/property.vue';

export default {
  name: "PrincipalSearch",
  components: {
    property,
    HomeSearch
  },
  metaInfo() {
    return {
      title: this.seo_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.seo_description
        }
      ]
    };
  },
  data() {
    return {
        show_map: false,
        properties: [],
        similar_properties: [],
        map_properties: [],
        order_search: 'price_desc',
        scroll_id: '',
        total:0,
        searching: true,
        show_search_form: true,
        form_filters: null,
        filters_applied: null,
        open_advanced_mode: false
    };
  },
  computed: {
    seo_title: function() {

      if (this.filters_applied) {
        let filters = this.filters_applied

        let tipo_desc =  typeof filters['property_type'] != "undefined" && filters['property_type'].length > 0 ? filters['property_type'][0] : 'imóveis'

        if (tipo_desc == "Sala" && filters['business_subfilter'] == "Comercial") {
          tipo_desc = "Sala Comercial"
        }

        tipo_desc = this.capitalize(tipo_desc)

        let finalidade_desc = filters['business'] == 'Locacao' ? 'para alugar' : 'à venda'

        let title = `${tipo_desc} ${finalidade_desc}`;

        if (filters['city']) {
          let city_desc = this.capitalize(filters['city'])

          title = `${tipo_desc} ${finalidade_desc} em ${city_desc}`

          if (filters['district'] && filters['district'].length > 0) {
            let distrct_desc = this.capitalize(filters['district'][0])
            title = `${tipo_desc} ${finalidade_desc} em ${distrct_desc}, ${city_desc}`

            if (filters['address']) {
              title = `${tipo_desc} ${finalidade_desc} na ${filters['address']} em ${distrct_desc}, ${city_desc}`
            }
          }
        }

        return title
      }
      return "..."
    },
    seo_description: function() {
      if (this.filters_applied) {

        let filters = this.filters_applied

        let tipo_desc =   typeof filters['property_type'] != "undefined" && filters['property_type'].length > 0 ? filters['property_type'][0] : 'imóveis'
        
        if (tipo_desc == "Sala" && filters['business_subfilter'] == "Comercial") {
          tipo_desc = "Sala Comercial"
        }
        
        let finalidade_desc = filters['business'] == 'Locacao' ? 'alugar' : 'comprar'

        let desc = `Esta buscando ${tipo_desc} para ${finalidade_desc}? Conte com a Apolar para encontrar a melhor opção de acordo com suas necessidades. Acesse e Confira!`;

        if (filters['city']) {
          let city_desc = this.capitalize(filters['city'])

          desc = `Se você está buscando ${tipo_desc} para ${finalidade_desc} em ${city_desc} conte com a Apolar. Temos um time de especialistas prontos para te ajudar. Acesse e Confira!`

          if (filters['district'] && filters['district'].length > 0) {
            let distrct_desc = this.capitalize(filters['district'][0])
            desc = `Buscando ${tipo_desc} para ${finalidade_desc} no ${distrct_desc} em ${city_desc}? Conte com a Apolar, temos um time de especialistas prontos para te ajudar. Acesse e Confira!`
          }
        }

        return desc
      }
      return "..."
    }
  },
  methods: {
      initjQuery() {

          const main_el = jQuery(this.$el)
          const advanced_search_el = main_el.find(".apolar-advanced-search")

          main_el.find(".advanced-filters").on("click", ()=>{
              advanced_search_el.css({"bottom": `-${advanced_search_el.height() + 40}px`})
              advanced_search_el.fadeIn('fast')
          })

      },

      searchOrdered() {
          this.search(this.$advanced_filters)
      },

      search(filters, append = false) {

        if (typeof filters == 'undefined') {
          return false
        }

        console.log("filters: ", filters)

        var search_filters = JSON.parse(JSON.stringify(filters))

        search_filters['order'] = this.order_search
        search_filters['size'] = 12
        search_filters['fields'] = ['IsFeiraoApolar','nomeEvento','tipo','transacao','finalidade','cidade','bairro','referencia','Quartos','condominio','garagem','dormitorios','areaterreno','area_total','banheiro','ValorAnterior','valor_considerado','situacao','FimPromocao','foto_principal','endereco','linksite','Selo','finalidade','popup_fotos']
        
        this.searching = true
        this.filters_applied = this.prepareFilters(search_filters)
        PropertiesService.search(this.filters_applied, 'main')
        .then(
          response => {
            var properties = response.data
            if (append) {
              properties = this.properties.concat(response.data)
            }
            Vue.set(this, "properties", properties)
            Vue.set(this, "scroll_id", response.scroll_id)
            Vue.set(this, 'total', response.total)
            this.searching = false

            console.log("this.filters_applied: ", this.filters_applied)

            if ( typeof this.filters_applied.reference != "undefined" &&  this.filters_applied.reference) {
              this.$refs['searchbox'].filters.reference = null
              Vue.set(this, '$advanced_filters', {})
              Vue.set(this, '$advanced_filters', this.filters)
            }
            

            if (properties.length == 0) {
              this.similarSearch()
            }

          }
        )
      },

      similarSearch() {

        let search_filters = JSON.parse(JSON.stringify(this.$advanced_filters))
        search_filters['order'] = this.order_search
        search_filters['size'] = 6
        search_filters['fields'] = ['IsFeiraoApolar','nomeEvento','tipo','transacao','finalidade','cidade','bairro','referencia','Quartos','condominio','garagem','dormitorios','areaterreno','area_total','banheiro','ValorAnterior','valor_considerado','situacao','FimPromocao','foto_principal','endereco','linksite','Selo','finalidade','popup_fotos']

        let prepared_search_filters = this.prepareFilters(search_filters)

        PropertiesService.similarSearch(prepared_search_filters, 'main')
        .then(
          response => {
            Vue.set(this, "similar_properties", response.data)
          }
        )

      },

      loadMore() {
        let next_page = {"scroll_id": this.scroll_id}
        this.search(next_page, true)
      },

      prepareFilters(filters) {

        filters['bedrooms'] = filters['bedrooms'] ? filters['bedrooms'].map(bed => typeof bed['id'] != 'undefined' ? bed['id']: bed ) : []
        filters['garage'] = filters['garage'] ? filters['garage'].map(gar => typeof gar['id'] != 'undefined' ? gar['id']: gar ) : []

        //caso o seja subfiltro por lancamentos deve considerar a transacao lancamentos
        if ( typeof filters['business_subfilter'] != "undefined" && filters['business_subfilter'] == "Lançamentos" ) {
          filters['business'] = "Lancamento"
        }

        //considera os tipos selecionados via botão tb
        if ( typeof filters['property_type_combo'] != "undefined" &&  filters['property_type_combo'].length > 0 ) {

          filters['property_type_combo'].forEach((type_combo)=>{

            if (type_combo) {
              let types_in_combo = type_combo.split('|')
              types_in_combo.forEach((type)=>{
                  type = type.trim()
                  if ( filters['property_type'].indexOf(type) == -1 &&  type != "Condominio" ) {
                    filters['property_type'].push(type)
                  }
              })
            }
            

          })


          console.log("filters['property_type']: ", filters['property_type'])

          this.execOnFormFilterLoad((sf)=>{
            setTimeout(() => {
                sf.$refs['vselectpropertytype'].value = filters['property_type']
            }, 100);
          })

        }

        if (filters['price_min'] || filters['price_max']) {
          filters['price'] = [this.strToFloat(filters['price_min']), this.strToFloat(filters['price_max'])]
        } else {
          delete filters['price']
        }

        return filters

      },

      handle_url_params(){
          //acesso direto, considerar parametros da url
          let url_params = JSON.parse(JSON.stringify(this.$route.params))
          let possible_purposes = ['residencial', 'comercial', 'lancamentos', 'triple-a', 'mensal', 'temporario']
          let possible_types = ['apartamento', 'barracao', 'casa', 'chacara', 'cobertura', 'conjunto', 'duplex', 'fazenda', 'flat', 
          'garagem', 'gardem', 'hotel', 'kitnet', 'lancamento', 'loft', 'ponto', 'predio', 'sala', 'sobrado', 'sobreloja',
          'studio', 'sitio', 'terreno', 'triplex', 'area', 'sala-comercial']
          
          let filter_purpose = possible_purposes.filter(p => typeof this.$route.query[p] != 'undefined')
          let purpose = filter_purpose.length > 0 ? filter_purpose[0] : null

          purpose = this.$route.params['type'] == "sala-comercial" ? "comercial" : purpose
          this.$route.params['type'] = this.$route.params['type'] == "sala-comercial" ? "sala" : this.$route.params['type']
         
          url_params['purpose'] = purpose
          url_params['type'] = this.$route.params['type']
          url_params['city'] = this.$route.params['city']
          url_params['district'] = this.$route.params['district']
          url_params['address'] = this.$route.params['address']

          if ( possible_types.filter(item => item == url_params.type).length == 0 ) {
            //quer dizer que acesso url direta sem usar tipo, desloca parametros
            url_params['type'] = null
            url_params['city'] = this.$route.params['type']
            url_params['district'] = this.$route.params['city']
            url_params['address'] = this.$route.params['district']
          }

          return url_params
      },

      loadUrlParameters() {

        //busca originada de busca anterior, ignora
        if ( Object.keys(this.$advanced_filters).length > 0 ) {
          return false
        }

        //aguarda os filtros de buscas serem carregados no componenet de fomulario de filtros       
        this.execOnFormFilterLoad((sf)=>{

              let url_params = this.handle_url_params()

              //seleciona o business no form, baseado no parametro da url
              sf.filters.business = url_params.transaction == "alugar" ? "Locacao" : "Vendas"

              //selecionar o subbusiness
              if ( url_params.purpose ) {
                let sub_business_possibles = sf.filters_options.sub_filters[sf.filters.business]
                let sub_business = sub_business_possibles.filter(item => this.slugify(item.label) == url_params.purpose)
                if ( sub_business.length > 0 ) {
                  sf.filters.business_subfilter = sub_business[0].label
                }
              } else {
                sf.filters.business_subfilter =  sf.filters.business == "Locacao" ? "Mensal" : "Residencial"
              }

              //seleciona cidade e bairro
              if ( url_params.city ) {
                let filter_city = sf.filters_options.cities.filter(item => this.slugify(item.city) == url_params.city)
                
                if ( filter_city.length > 0 ) {
                  let city = filter_city[0]
                  sf.filters.city = city.city
                  sf.$refs['vselectcity'].value = sf.filters.city

                  if (url_params.district) {
                    let filter_district = city.districts.filter(item => this.slugify(item) == url_params.district)
                    if ( filter_district.length > 0 ) {
                      let district = filter_district[0]
                      sf.filters.district = [district]
                      //sf.$refs['vselectdistrict'].value = sf.filters.district
                    }
                  }
                }
              }

              //seleciona o tipo do imóvel
              if ( url_params.type ) {
                let filter_type = sf.filters_options.types.filter(item => this.slugify(item) == url_params.type)
                if ( filter_type.length > 0 ) {
                  let type = filter_type[0]
                  sf.filters.property_type = [type]
                  //sf.$refs['vselectpropertytype'].value = sf.filters.property_type
                }
              }

              //seleciona o endereco
              if ( url_params.address ) {
                //console.log("sf.filters_options.adresses: ", sf.filters_options.adresses)
                let filter_address = sf.filters_options.adresses.filter(
                  item => this.slugify(item.address) == url_params.address && 
                  item.city == sf.filters.city && 
                  item.district == sf.filters.district[0]
                )

                if ( filter_address.length > 0 ) {
                  sf.use_advanced_filters =  false
                  let addrs = filter_address[0]
                  let addrs_str = `${addrs.address}, ${addrs.district}, ${addrs.city}`
                  sf.filters.address = addrs.address.trim()
                  sf.filters.open_search = addrs_str
                  setTimeout(() => {
                    sf.$refs['vselectsearchby'].$children[0].value = sf.filters.open_search
                    if ( sf.filters.property_type && sf.filters.property_type.length > 0 ) {
                      //sf.$refs['vselectpropertytype'].value = sf.filters.property_type
                    } else {
                      //sf.$refs['vselectpropertytype'].value = []
                    }
                  }, 100);
                }
              }  

              this.open_advanced_mode = true

               //load filters from query string
              if ( this.$route.query && Object.keys(this.$route.query).length > 0 ) {
                  let fields_type_array = ['bedrooms', 'garage', 'district', 'property_type']
                  Object.keys(this.$route.query).forEach((qfield) => {
                      let value = this.$route.query[qfield]
                      if (fields_type_array.indexOf(qfield) !== -1) {
                          if (typeof value != 'object') {
                              value = [value]
                          }

                          if (qfield == 'bedrooms' || qfield == 'garage') {
                            value = value.map(v=>parseInt(v))
                          }
                          
                      }
                      //use_filters[qfield] = value
                      Vue.set(sf.filters, qfield, value)
                  })
                }

              //aplica a busca            
              sf.search()
        })

      },

      execOnFormFilterLoad(callback) {
        let check_search_box_load = setInterval(() => {
          if ( typeof this.$refs['searchbox'] != "undefined" && this.$refs['searchbox'].filters_options.types.length > 0 ) {
              clearInterval(check_search_box_load)
              let searchform = this.$refs['searchbox']
              if (callback) {
                callback(searchform)
              }
            }
        }, 10)
      }
  },
  mounted() {
      this.initjQuery()

      if ( this.checkIsSmallScreen  ) {
        this.show_search_form = false
      }
  },
  created() {
    this.loadUrlParameters()
    this.open_advanced_mode = true
    //só buscara de primeira se houver filtro aplicado anteriormenete..
    if ( Object.keys(this.$advanced_filters).length > 0 ) {
      this.search(this.$advanced_filters)
    }

    this.execOnFormFilterLoad((sf)=>{
      sf.set_advanced_filters(true)
    })

  },
  watch:{
    $advanced_filters: function(filters) {
      this.search(filters)
    }
  }
};
</script>

<style lang="scss" scoped>

  

  
</style>
