class Lang {
    constructor(lang) {
        try {
            this.lang_lib = require(`./repo/${lang}`);
        } catch (error) {
            this.lang_lib = null
        }
    }

    trans(str_id) {

        if (!this.lang_lib) {
            return str_id
        }
        
        let value = this.lang_lib.default[str_id]
        return typeof value == "undefined" ? str_id : value
    }

    pattern_trans(str_id, pattern) {

        if (!this.lang_lib) {
            return str_id
        }
        
        let pattern_tranlated = this.lang_lib.default[pattern]

        if ( typeof pattern_tranlated == 'undefined' ) {
            return str_id
        }

        let pattern_fix_parts = pattern.split('%s')
        
        let str_only_values = str_id
        pattern_fix_parts.forEach((str)=>{
            if (str && str != '.' && str != ',') {
                str_only_values = str_only_values.replace(str,'||')
            }
        })

        console.log("list_only_values: ", list_only_values)

        let list_only_values = str_only_values.split("||").filter(str => str)

        let traslated =  this.parse(pattern_tranlated, list_only_values)
        return traslated
    }

    parse(str) {
        var args = arguments[1],
            i = 0;
        return str.replace(/%s/g, () => args[i++]);
    }

}

export default Lang