var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-principal-faq"},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v(_vm._s(_vm.__('Política de Cookies')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[(_vm.lang=='ptBR')?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23)]):_vm._e(),(_vm.lang=='es')?_c('div',[_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32),_vm._m(33),_vm._m(34),_vm._m(35),_vm._m(36),_vm._m(37),_vm._m(38),_vm._m(39),_vm._m(40),_vm._m(41),_vm._m(42),_vm._m(43),_vm._m(44),_vm._m(45),_vm._m(46),_vm._m(47)]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Esta política de cookies, tem como objetivo descrever as\n                            práticas que seguimos para respeitar a privacidade de todos os visitantes do nosso\n                            site.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Ao acessar o site da Apolar você autoriza que usemos cookies\n                            de acordo com a presente Política. Caso não concorde, você pode configurar seu navegador\n                            para recusar cookies, mas consequentemente poderá não conseguir acessar ou usar partes\n                            do site.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("O que são cookies?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Cookies são arquivos ou informações que podem ser armazenadas\n                            em seus dispositivos quando você visita o site ou utiliza canais digitais da Apolar.\n                            Servem para identificá-lo, lembrar de suas preferências e facilitar a administração\n                            efetiva do website. Permite também personalizar a navegação de acordo com o seu perfil,\n                            tornando a utilização de nossas plataformas cada vez mais agradável.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("Como a Apolar usa os cookies?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Nós usamos cookies para:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Correto\n                                funcionamento do site")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Personalizar a\n                                navegação de acordo com seu perfil, proporcionando melhores experiências a\n                                você")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Acessar a sua\n                                conta na Área do Cliente ")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Auxiliar você\n                                no preenchimento de formulários")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Realizar\n                                métricas de engajamento das plataformas, campanhas e promoções da Apolar\n                                Imóveis")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Dispor a você\n                                publicidade direcionada ao seu interesse")])]),_c('li',{staticStyle:{"font-weight":"400"},attrs:{"aria-level":"1"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Aprimorar os\n                                serviços e/ou funcionalidades do site e das plataformas da Apolar Imóveis")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("Cookies utilizados em nosso site")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('strong',[_vm._v("Cookies obrigatórios:")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("São essenciais para que os websites da Apolar carreguem\n                            adequadamente. Você pode configurar seu computador para bloquear ou alertar sobre a\n                            coleta desses cookies, mas isso poderá comprometer sua navegação.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('b',[_vm._v("Cookies de desempenho:")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" Nos ajudam a entender como os visitantes interagem com as\n                            páginas da Apolar. Eles nos ajudam a saber o número de visitas e quais são as páginas\n                            mais e menos populares. Todas as informações coletadas são anônimas e em caso de\n                            desabilitação, sua navegação não será registrada.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('b',[_vm._v("Cookies de funcionalidade:")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" Permitem que as páginas da Apolar Imóveis se lembrem de suas\n                            escolhas, proporcionando uma melhor experiência. A ausência de coleta desses dados pode\n                            tornar a experiência no website menos funcional.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('b',[_vm._v("Cookies de publicidade:")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" São utilizados para fornecer conteúdo mais relevante para\n                            você, entregando anúncios de acordo com seu interesse. Se não permitir esses cookies,\n                            você receberá menos publicidade de nossas novidades selecionadas de acordo com seu\n                            perfil.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('b',[_vm._v("Cookies de redes sociais: ")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("São estabelecidos por serviços de redes sociais,\n                            possibilitando o compartilhamento de conteúdo para mídias externas.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("Como excluir e bloquear cookies?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Alguns navegadores aceitam cookies automaticamente, mas você\n                            pode alterar as configurações de seu navegador para recusar cookies acessando o recurso\n                            de Ajuda na barra de ferramenta de seu navegador.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Para gerenciar os cookies no aplicativo:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Se o seu dispositivo for Android abra o aplicativo do Google\n                            Chrome em seu smartphone, vá na aba de “Menu” selecionando os três pontinhos do canto\n                            superior direito. Abrindo esta aba, selecione a opção “Configurações”, role para baixo\n                            até encontrar a opção “Configurações do site” e selecione-a. Clicando na opção “Cookies”\n                            você poderá ativar ou desativar a opção de Cookies, também caso você queira que o\n                            navegador faça o bloqueio de cookies de terceiros, é só marcar esta opção.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Sendo o seu dispositivo iOS, abra o aplicativo “Ajustes” em\n                            seu iPhone, role até encontrar o “Safari” e clique nele. Vá até a opção “Privacidade e\n                            segurança” e ative ou desative “Bloquear todos os cookies”.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Importante alertar que ao desabilitar os cookies que\n                            utilizamos, pode impactar sua experiência em nosso site, por exemplo, você talvez não\n                            possa visitar certas áreas de uma página do nosso site ou talvez não receba informações\n                            personalizadas quando visitar uma página do nosso site.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Caso você use dispositivos diferentes para visualizar e\n                            acessar o nosso site (por exemplo, computador, smartphone, tablet, etc.) deve\n                            assegurar-se de que cada navegador de cada dispositivo está ajustado para atender suas\n                            preferências quanto aos cookies.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("Mudanças na Política de Cookies")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("A Apolar reserva-se no direito de alterar esta Política de\n                            Cookies a qualquer momento, então antes de usar nossos serviços, consulte o portal.\n                            Lembre-se: ao continuar a acessar a plataforma depois que tais alterações passarem a\n                            valer, você estará concordando em estar vinculado à nova versão da Política.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("Contato")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("Caso precise de qualquer suporte ou tenha alguma dúvida,\n                            pedido ou sugestão em relação a essa Política, por favor entre em contato através do\n                            e-mail ")]),_c('a',{attrs:{"href":"mailto:lgpdcontato@apolar.com.br"}},[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("lgpdcontato@apolar.com.br")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Esta política de cookies tiene como objetivo describir las prácticas que seguimos para respetar la privacidad de todos los visitantes de nuestro sitio web.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Al acceder al sitio de Apolar, usted autoriza que usemos cookies de acuerdo con esta Política. Si no está de acuerdo, puede configurar su navegador para rechazar cookies, pero, como consecuencia, podría no conseguir acceder o usar partes del sitio.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("¿Qué son cookies?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Los cookies son archivos o información que pueden almacenarse en sus dispositivos cuando visita el sitio o utiliza canales digitales de Apolar. Sirven para identificarlo, recordar sus preferencias y facilitar la administración efectiva del sitio web. También permiten personalizar la navegación según su perfil, haciendo que el uso de nuestras plataformas sea cada vez más agradable.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("¿Cómo usa Apolar los cookies?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Usamos cookies para:\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("El correcto funcionamiento del sitio")]),_c('li',[_vm._v("Personalizar la navegación de acuerdo con su perfil, proporcionando mejores experiencias a usted")]),_c('li',[_vm._v("Acceder a su cuenta en el Área del Cliente")]),_c('li',[_vm._v("Ayudarle en el llenado de formularios")]),_c('li',[_vm._v("Realizar métricas de compromiso de las plataformas, campañas y promociones de Apolar Inmuebles")]),_c('li',[_vm._v("Ofrecerle publicidad dirigida a sus intereses")]),_c('li',[_vm._v("Mejorar los servicios y/o funcionalidades del sitio y las plataformas de Apolar Inmuebles")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("Cookies utilizados en nuestro sitio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('strong',[_vm._v("Cookies obligatorios:")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Son esenciales para que los sitios web de Apolar se carguen adecuadamente. Puede configurar su computadora para bloquear o alertar sobre la recopilación de estos cookies, pero esto podría comprometer su navegación.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('strong',[_vm._v("Cookies de rendimiento:")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Nos ayudan a entender cómo los visitantes interactúan con las páginas de Apolar. Nos permiten saber el número de visitas y cuáles son las páginas más y menos populares. Toda la información recopilada es anónima y, en caso de deshabilitación, su navegación no será registrada.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('strong',[_vm._v("Cookies de funcionalidad:")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Permiten que las páginas de Apolar Inmuebles recuerden sus elecciones, proporcionando una mejor experiencia. La ausencia de recopilación de estos datos puede hacer que la experiencia en el sitio web sea menos funcional.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('strong',[_vm._v("Cookies de publicidad:")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Son utilizados para ofrecer contenido más relevante para usted, entregando anuncios de acuerdo con su interés. Si no permite estos cookies, recibirá menos publicidad de nuestras novedades seleccionadas según su perfil.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('strong',[_vm._v("Cookies de redes sociales:")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Son establecidos por servicios de redes sociales, posibilitando el compartir contenido en medios externos.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("¿Cómo eliminar y bloquear cookies?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Algunos navegadores aceptan cookies automáticamente, pero puede cambiar las configuraciones de su navegador para rechazar cookies accediendo al recurso de Ayuda en la barra de herramientas de su navegador.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Para gestionar los cookies en la aplicación:\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('strong',[_vm._v("Si su dispositivo es Android")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            abra la aplicación del Google Chrome en su smartphone, vaya a la pestaña de “Menú” seleccionando los tres puntitos en la esquina superior derecha. Al abrir esta pestaña, seleccione la opción “Configuraciones”, desplácese hacia abajo hasta encontrar la opción “Configuraciones del sitio” y selecciónela. Al hacer clic en la opción “Cookies”, podrá activar o desactivar la opción de Cookies. También, si desea que el navegador bloquee cookies de terceros, solo marque esta opción.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"text-decoration":"underline"}},[_c('strong',[_vm._v("Si su dispositivo es iOS")])]),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            abra la aplicación “Ajustes” en su iPhone, desplácese hasta encontrar “Safari” y haga clic en él. Vaya a la opción “Privacidad y seguridad” y active o desactive “Bloquear todas las cookies”.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Es importante señalar que al deshabilitar los cookies que utilizamos, puede afectar su experiencia en nuestro sitio. Por ejemplo, es posible que no pueda visitar ciertas áreas de una página de nuestro sitio o que no reciba información personalizada cuando visite una página de nuestro sitio.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Si utiliza diferentes dispositivos para ver y acceder a nuestro sitio (por ejemplo, computadora, smartphone, tablet, etc.), debe asegurarse de que cada navegador de cada dispositivo esté configurado para atender sus preferencias respecto a los cookies.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("Cambios en la Política de Cookies")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Apolar se reserva el derecho de cambiar esta Política de Cookies en cualquier momento. Antes de usar nuestros servicios, consulte el portal. Recuerde: al continuar accediendo a la plataforma después de que tales cambios entren en vigor, estará de acuerdo en estar vinculado a la nueva versión de la Política.\n                        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('b',[_vm._v("Contacto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("\n                            Si necesita cualquier soporte o tiene alguna duda, solicitud o sugerencia en relación con esta Política, por favor, póngase en contacto a través del correo electrónico "),_c('a',{attrs:{"href":"mailto:lgpdcontato@apolar.com.br"}},[_vm._v("lgpdcontato@apolar.com.br")]),_vm._v(".\n                        ")])])
}]

export { render, staticRenderFns }