<template>
  <div class="page-principal-home">

    <section class="home-search-section">
      <div class="container">
        <HomeSearch ref="searchbox" :clearFilterOnOpen="true" />
      </div>
    </section>
  
    <section v-if="lang == 'ptBR'" class="box-produtos-section">
      <div class="container">
        <BoxProdutos/>
      </div>
    </section>


    <section v-if="lang == 'ptBR'" class="box-properties-section feirao-properties-section">
      <div class="container">
        <PropertiesCarousel
          :link_label="__('Visitar a página do Feirão Apolar')"
          :link_url="'/feirao-apolar'"
          :sub_business="'feirao-apolar'"
        >
          <template v-slot:title>
            {{__('Feirão Apolar')}} <img width="61" height="38" alt="Feirão Apolar" src="@/assets/img/feirao-apolar-logo.webp"/>
          </template>

          <template v-slot:subtitle>
            {{__('Imóveis com valores inacreditáveis!')}}
          </template>

          <template v-slot:footer>
            <a target="_blank" href="/feirao-apolar" class="view-all">{{__('Ver todas as ofertas')}}</a>
          </template>
        </PropertiesCarousel>
      </div>
    </section>


    <section v-if="lang == 'ptBR'" class="box-properties-section lancamento-properties-section">
      <div class="container">
        <PropertiesCarousel
          :link_label="__('Visitar a página de Lançamentos Apolar')"
          :link_url="'/lancamentos'"
          :sub_business="'lancamentos'"
        >
          <template v-slot:title>
            {{__('Lançamentos')}}
          </template>

          <template v-slot:subtitle>
            {{__('Confira os novos empreendimentos Apolar')}}
          </template>

          <template v-slot:footer>
            <a target="_blank" href="/lancamentos" class="view-all">{{__('Ver lançamentos')}}</a>
          </template>
        </PropertiesCarousel>
      </div>
    </section>

    <section v-if="lang == 'es'" class="box-properties-section lancamento-properties-section">
      <div class="container">
        <PropertiesCarousel
          :link_label="null"
          :link_url="''"
          :sub_business="'other'"
        >
          <template v-slot:title>
            {{__('Imóveis Anúnciados')}}
          </template>

          <template v-slot:subtitle>
            {{__('Outras opções de imóveis')}}
          </template>

          <template v-slot:footer>
          </template>
        </PropertiesCarousel>
      </div>
    </section>



    <section v-if="lang == 'ptBR'" class="apolar-procura-section">
      <div class="container">
        <ProcuraSection/>
      </div>
    </section>

    <section class="box-advertise-search-section advertise-box">
      <div class="container">
        <BoxAdvertise/>
      </div>
    </section>

    <section class="box-advertise-search-section search-box">
      <div class="container">
        <BoxSearch/>
      </div>
    </section>

    <section class="prepared-search-section">
      <div class="container">
        <PreparedSearchs/>
      </div>
    </section>

    <!-- <scamAlertVue/> -->
  </div>
</template>
<script>
import Vue from "vue";
import "@/assets/scss/custom/principal/_home.scss"
import Immobile from "../components/apolar/immobile";
import HomeSearch from "../components/apolar/principal/home-search.vue"
import PreparedSearchs from "../components/apolar/principal/prepared-searchs.vue"
import BoxAdvertise from "../components/apolar/principal/box-advertise.vue";
import BoxSearch from "../components/apolar/principal/box-search.vue";
import ProcuraSection from "../components/apolar/principal/procura-section.vue";
import BoxProdutos from "../components/apolar/principal/box-produtos.vue";
import PropertiesCarousel from "../components/apolar/principal/properties-carousel.vue"

import PropertiesService from "../../services/PropertiesService";
import scamAlertVue from '../components/apolar/principal/scam-alert.vue';

export default {
  name: "home",
  components: {
    Immobile,
    HomeSearch,
    PreparedSearchs,
    BoxAdvertise,
    BoxSearch,
    ProcuraSection,
    BoxProdutos,
    PropertiesCarousel,
    scamAlertVue
  },
  metaInfo() {
    let title = this.lang == "ptBR" ? "Imóveis em Curitiba - Imóveis a Venda e Locação" : "Inmobiliaria en Paraguay - Propiedades en Venta y Alquiler";
    let description = this.lang == "ptBR" ? "Comprar ou alugar imóveis é na Apolar. Encontre tudo sobre o mercado imobiliário aqui! Entre em contato pelo nosso site, ligue ou visite uma de nossas lojas." : "Encuentre propiedades en venta y alquiler en Paraguay con Apolar Inmobiliaria. Ofrecemos las mejores opciones de casas, apartamentos, terrenos y más."
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        }
      ]
    };
  },
  data() {
    return {
    
    };
  },
  computed: {


  },
  methods: {
    
  },
  mounted() {
 
  }
};
</script>
