<template>
  <section class="property-details-sidebar">
    <div class="property-side-bar">
      <PropertyDetailsFormDefault :immobile="immobile"></PropertyDetailsFormDefault>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import jQuery from "jquery";
import { Carousel, Slide } from "vue-carousel";
import PropertyDetailsFormDefault from "./property-details-form-default.vue";

export default {
  components: {
    Carousel,
    Slide,
    PropertyDetailsFormDefault
  },
  props: {
    immobile: {
      default: {}
    }
  },
  data() {
    return {};
  },
  methods: {
    initjQuery() {

      jQuery(document).ready(() => {

        if (!this.isMobile) {
            const property_details_sidebar = jQuery(this.$el);
            const property_side_bar = property_details_sidebar.find(".property-side-bar");
            
            // property_details_sidebar.css({
            //     height: property_side_bar.height() + 30,
            // });

            // jQuery(window).on("scroll", () => {
            //     console.log("Scroll....");
            //     console.log(jQuery(window).scrollTop())
            //     console.log(jQuery("#property-slide").offset().top + jQuery("#property-slide").height())
            // });
        }

      });
    },
  },
  mounted() {
    this.initjQuery();
  },
};
</script>
<style>
</style>