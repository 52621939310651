<template>
    <div v-if="modalOpened" class="scam-alert-modal-component">
       <div v-on:click="closeModal" class="backdrop"></div>
       <div class="modal-content-alert">
            <svg v-on:click="closeModal" fill="#e8e8e8" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#e8e8e8"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm3.707,12.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414L13.414,12Z"></path></g></svg>
            <img src="@/assets/img/apolar-popup-golpe.webp" alt="Alerta de Golpe!">
       </div>
    </div>
</template>
<script>

export default {
  components: {
  },
  computed: {
      
  },
  data() {
   return {
        modalOpened: false
   }
  },
  methods: {
    closeModal() {
        this.modalOpened = false
    }
  },
  mounted() {
    setTimeout(() => {
        this.modalOpened = true
    }, 2000);
  }
};
</script>

<style lang="scss">
    .scam-alert-modal-component {
        position: relative;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        .backdrop {
            content: " ";
            position: fixed;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.5);
            left: 0;
            top: 0;
            z-index: 10;
        }

        .modal-content-alert {
            width: 600px;
            border: 0;
            background: transparent;
            z-index: 100;
            position: relative;

            @media (max-width: 599px) {
                width: 90%;
            }
            img {
                width: 100%;
            }
            svg {
                position: absolute;
                right: -25px;
                top: -18px;
                cursor: pointer;
                width: 35px;

                @media (max-width: 599px) {

                }
            }
        }
    }
</style>
